import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom'
import { store } from '../Store'
import TradeLogo from '../assets/trade-logo.png'
import FredomLogo from '../assets/freedom_logo.png'
import LogoutIcon from  "../assets/ic-logout.png"
import LabeledInputField from '../utils/custom-fields'
import tooltipIcon from '../assets/ic-help_default.png'
import img_asurion from '../assets/img-asurion.png'
import { useActions } from '../Action'
import Select from 'react-select'

const moment = require('moment')

const Header = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { StoreAgentName } = state.Login
  const { masterdata, resources, Login, roles, LogisticsTab, UpgradeTab, TradeInTab, isTrade, ReportsTab, ContactUsTab, partnerStoreName } = state
  const { PartnerStoreCode, PartnerParentStoreCode, StoreAgentRole } = Login
  const stores = masterdata && masterdata.Stores
  const { register, errors, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      PartnerStoreCodeNbr: state && state.PartnerStoreCodeNbr
    },
  })
  const logOut = () => {
    let confirmation = window.confirm('Do you want to logout?')
    if (confirmation == true) {
      window.location.replace('/')
    }
  }

  let defaultPartnerStoreCode = PartnerStoreCode;
  const tabRoles = roles && roles.filter(r => r.menu === 'Header' && r.section === 'Tabs') &&
    roles && roles.filter(r => r.menu === 'Header' && r.section === 'Tabs')[0];
  const storeRoles = roles && roles.filter(r => r.menu === 'Header' && r.section === 'StoreSelection') &&
    roles && roles.filter(r => r.menu === 'Header' && r.section === 'StoreSelection')[0];

  let show = document.querySelectorAll('.show');
  let headertooltipid = show && show[0] && show[0].id
  let tooltipActive = show && (headertooltipid === 'header-tooltip') ? true : false
  // const showStoreName = (StoreAgentRole === "Store" || StoreAgentRole === 'Store Manager') ? (workingAs ? true : false) : true
  const [currentDate, setCurrentDate] = useState(moment().format('D MMMM YYYY h:mm'));
  function displayTime() {
    setInterval(() => {
      setCurrentDate(moment().format('D MMMM YYYY h:mm'))
    }, 1000)
  }

  const logoutFunc = () => {
    window.location.replace('/')
  }

  const logisticsClick = () => {
    actions.setLogisticsTab(true)
    actions.setReportingTab(false)
    actions.setContactUsTab(false)
    actions.setUpgradeTab(false)
    actions.setTradeInTab(false)
    actions.clearTradeDetails()
    history.replace('/logistics')
  }

  const upgradeClick = () => {
    actions.setUpgradeTab(true)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setContactUsTab(false)
    actions.setTradeInTab(false)
    history.replace('/upgrade')
  }
  const tradeInClick = () => {
    actions.setTradeInTab(true)
    actions.setUpgradeTab(false)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setContactUsTab(false)
    history.replace('/tradein')
  }

  const reportingClick = () => {
    actions.setReportingTab(true)
    actions.setLogisticsTab(false)
    actions.setContactUsTab(false)
    actions.setUpgradeTab(false)
    actions.setTradeInTab(false)
    actions.clearTradeDetails()
    history.replace('/reports')
  }

  const contactUsClick = () => {
    actions.setReportingTab(false)
    actions.setLogisticsTab(false)
    actions.setContactUsTab(true)
    actions.setUpgradeTab(false)
    actions.setTradeInTab(false)
    actions.clearTradeDetails()
    history.replace('/contactus')
  }
  
  const setPartnerStoreCode = (value) => {
    actions.setPartnerStoreCode(value)
    let filteredStore = stores && stores.filter((s) => s.PartnerStoreCode === value && s.PartnerParentStore === PartnerParentStoreCode)
    let storeNameData = filteredStore && filteredStore[0] && filteredStore[0].PartnerStoreName
    actions.setPartnerStoreName(storeNameData)
  }

  const checkStoreCode = (value) => {
    if (!value) {
      setValue("PartnerStoreCodeNbr", defaultPartnerStoreCode);
      setPartnerStoreCode(defaultPartnerStoreCode);
    }
  }
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();
  return (
    <header>
      <div className={`flex justify-between  items-center xl:mx-100 pt-30 mx-50`}>
       <div className='flex flex-row items-center'>
          {isTrade ? 
          <img className="h-40 pb-1 inline-block mr-70" src={TradeLogo} alt="asurion-img" /> 
          :
          <img className="h-32 pb-1 inline-block mr-70" src={FredomLogo} alt="asurion-img" />
          }

          <ul className="inline-flex">
              
                  { !isTrade && <li className={`${UpgradeTab ? 'font-bold text-lightBlue border-b-4 border-textPurple' : 'text-textColor'} lg:pb-10 inline-block font-AvenirNextRegular  text-base leading-20 mr-40 cursor-pointer`}>
                    <a onClick={() => { upgradeClick() }}>UPGRADE</a>
                  </li>
                  }

                 {isTrade && <li className={`${TradeInTab ? 'font-bold text-lightBlue border-b-4 border-textPurple' : 'text-textColor'} lg:pb-10 inline-block font-AvenirNextRegular  text-base leading-20 mr-40 cursor-pointer`}>
                    <a onClick={() => { tradeInClick() }}>TRADE-IN</a>
                  </li>
                 }

                {tabRoles['Logistics'][agentRole] === 'SHOW' &&
                  <li className={`${LogisticsTab ? 'font-bold text-lightBlue border-b-4 border-textPurple' : 'text-textColor '} pb-10 inline-block font-AvenirNextRegular  text-base leading-20  mr-40 cursor-pointer`}>
                    <a onClick={() => { logisticsClick() }}>LOGISTICS</a>
                  </li>}

                {/* {tabRoles['Exceptions'][agentRole] === 'SHOW' &&
                  <li className={`${ExceptionsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg  text-base leading-20 text-black mr-20 cursor-pointer`}>
                    <a onClick={() => { exceptionClick() }}>Exceptions</a>
                  </li>} */}

                {tabRoles['Reporting'][agentRole] === 'SHOW' &&
                  <li className={`${ReportsTab ? 'font-bold text-lightBlue border-b-4 border-textPurple' : 'text-textColor '} pb-10 inline-block font-AvenirNextRegular  text-base leading-20  mr-40 cursor-pointer`}>
                    <a onClick={() => { reportingClick() }}>REPORTING</a>
                  </li>}

                {/* {
                  <li className={`${ContactUsTab ? 'font-bold text-lightBlue border-b-4 border-textPurple' : 'text-textColor '} pb-10 inline-block font-AvenirNextRegular text-base leading-20 mr-40 cursor-pointer`}>
                    <a onClick={() => { contactUsClick() }}>CONTACT US / FAQ</a>
                </li>} */}

          </ul>
        </div>
        <div className=" lg:block">
          <div className=" lg:block">
            {/* <div id="ct" className="px-3 text-xs text-textColor text-right" /> */}
            <p className="text-right text-xs h-3 large-desktop:mt-28 mt-1.5 mb-10 ">{currentDate}</p>
            {displayTime()}
          </div>
          <div className=" lg:block">
            
            <ul className="flex items-center">
              <li className="ml-2 font-bold text-lightBlue text-base">{StoreAgentName}</li>
              <li className="ml-10 text-sm">{partnerStoreName}</li>
              {/* <div>{
              !ContactUsTab &&
                < span className="inline-block mr-15 text-white" >
                  <img src={tooltipIcon} className={`${tooltipActive ? 'image-click ' : ''} image-hover cursor-pointer inline-block `} data-tip data-for="header-tooltip" data-event="click focus"></img>
                  <ReactTooltip delayHide={0} delayShow={1} globalEventOff="click" place="left" id="header-tooltip" className={`header-tooltip`} type="error" clickable arrowColor="transparent">
                    <div className="text-dark-gray text-15 leading-18 font-AvenirNextRegular">
                      <p className="">
                        For store related queries, please reach out to
                        <br />
                        <a className="text-purple-400" href="mailto:Harveynorman-store_enquiries@asurion.com">
                        Harveynorman-store_enquiries@asurion.com
                        </a></p>
                      <div className="mt-10">
                        <img className="float-right inline-block" src={img_asurion} alt="asurion-img" />
                      </div>
                    </div>
                  </ReactTooltip>
                </span >}
              </div> */}
              <img className="ml-10 cursor-pointer object-contain" src={LogoutIcon} onClick={() => logOut()}/>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
