import React, { useContext, useEffect } from 'react';
import { store } from '../Store';
import footerLogo from '../assets/img-asurion.png';
import { useHistory } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer h-6">
      <div>
        <img className="ml-70 w-24 object-contain" src={footerLogo} alt="asurion" />
      </div>
    </div>
  )
}
export default Footer
