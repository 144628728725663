import React from 'react'

import ContactForm from './contactform'

const ContactUs = () => (
  <div>
    <div className="min-h-screen">
      <div className="mx-50 font-AvenirNextRegular">
      <ContactForm />
      </div>
    </div>
  </div>
)
export default ContactUs
