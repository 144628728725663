import React from 'react'
import { MemoryRouter as Router } from 'react-router-dom'
import UpgradeDetails from './upgradeDetails'

const Upgrade = () => {

  return (
    <Router> 
      <div className="min-h-screen">
      <div className="px-20 font-AvenirNextRegular">   
          <UpgradeDetails />   
      </div>
      </div>
    </Router>
  )
}
export default Upgrade;
