const schemas = {
  verification: {
    emailId: {
      required: true
    },
    vodafoneAgentId: {
      required: true
    },
    username: {
      required: true
    },
    password: {
      required: true,
      validate: {
        //password: (value) => value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*(\")}{;:'||\,<>?/])(?!.* )")!= null,
  
        //max: (value) => value.length <= 8,
        //min: (value) => value.length >= 8,
      },
    },
    verificationCode: {
      required : true
    },
    
    customerTradeId: {
      required: true,
      validate: {
        // numeric : value => value.match(/^[0-9]+$/i) != null,
        // max: value => value.length <= 8,
        // min: value => value.length >= 8
      },
    },
    storeName: {
      required: true,
    },
    currentPassword: {
      required: true,
      validate: {
        password: (value) => value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*(\")}{;:'||\,<>?/])(?!.* )")!= null,
        //max: (value) => value.length <= 8,
        min: (value) => value.length >= 8,
      },
    },
    newPassword: {
      required: true,
      validate: {
        password: (value) => value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*(\")}{;:'||\,<>?/])(?!.* )")!= null,
        //max: (value) => value.length <= 8,
        min: (value) => value.length >= 8,
      },
    },
    confirmNewPassword: {
      required: true,
      validate: {
        password: (value) => value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*(\")}{;:'||\,<>?/])(?!.* )")!= null,
        //max: (value) => value.length <= 8,
        min: (value) => value.length >= 8,
      },
    },
  },
  logistics: {
    TradeID: {
    },
    StartDate: {
    },

    EndDate: {
    },
    Make: {
    },
    Model_Varriant: {
    },
    Condition: {
    }
  },
  reporting: {
    TradeID: {
    },
    StartDate: {
    },
    EndDate: {
    },
    SearchByState: {
    },
    Satus: {
    },
    StoreName: {
    }
  },
  customerInfo: {
    mobileNumber: {
      label: 'Mobile Number',
      required: true,
      validate: {
        invalidnum: (value) => {
          if (value.match(/^(04|614)[0-9]+$/i) != null) {
            return true
          }
          else {
            return false
          }
        },
        mobileLength1: (value) => {
          if (value.substring(0, 2) === '04') {
            if (value.length > 10) {
              return false
            }
            else {
              return true
            }
          }
        },
        mobileLength2: (value) => {
          if (value.substring(0, 3) === '614') {
            if (value.length > 11) {
              return false
            }
            else {
              return true
            }
          }
        },
        mobileFormat: (value) => {
          if (value.substring(0, 2) === '04') {
            if (value.length !== 10) {
              return false
            }
            else {
              return true
            }
          }
          else if (value.substring(0, 3) === '614') {
            if (value.length !== 11) {
              return false
            }
            else {
              return true
            }
          }
        },
      },
    },
    billingAccountNumber: {
      label: 'Billing Account Number',
      required: true,
      validate: {
        numeric: (value) => value.match(/^[0-9]+$/i) != null,
      },
    },
    serviceManagementNumber: {
      label: 'Service Order ID',
      required: true,
      // validate: {
      //   numeric: (value) => value.match(/^[0-9a-zA-Z]+$/i) != null,
      // },
      minLength: 0,
      maxLength: 15,
    },
    firstName: {
      required: true,
      validate: {
        alpha: (value) => value.match(/^[a-zA-Z]+$/i) != null
      },
      minLength: 0,
      maxLength: 50,
    },
    lastName: {
      required: true,
      validate: {
        alpha: (value) => value.match(/^[a-zA-Z]+$/i) != null
      },
      minLength: 0,
      maxLength: 50,
    },
    emailAddress: {
      required: true,
      validate: {
        email: (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != null,
      },
    },
    addressLine1: {
      required: true,
      validate: {
        maxAddress: (value) => value.length <= 40,
        minAddress: (value) => value.length >= 0,
      },
    },
    addressLine2: {
      validate: {
        maxAddress: (value) => value.length <= 40,
        minAddress: (value) => value.length >= 0
      }
    },
    addressLine3: {
      validate: {
        maxAddress: (value) => value.length <= 40,
        minAddress: (value) => value.length >= 0,
      }
    },
    postalCode: {
      required: true,
      validate: {
        numericPostCode: (value) => value.match(/^[0-9]+$/i) != null,
        maxPostalCode: value => value.length <= 4,
        minPostalCode: value => value.length >= 4
      },
    },
    city: {
      required: true,
      minLength: 1,
      maxLength: 50,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
  },
}

const validator = (formName, fieldName) => {
  if (schemas[formName] && schemas[formName][fieldName]) {
    return schemas[formName][fieldName]
  }
}

export default validator
