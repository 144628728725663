export const DeviceTypes = {
  MACBOOK: 'MACBOOK',
  SURFACE: 'SURFACE',
  PHONE_TABLET: 'PHONE_TABLET',
  LVD_LAPTOP: 'LVD_LAPTOP',
  LVD_PWT: 'LVD_PWT'
}

export const TradeStatus = {
  OPEN: 'OPEN',
  SUBMITTED: 'SUBMITTED',
  BATCHED: 'BATCHED'
}

export const DeviceMakes = {
  APPLE: 'APPLE',
  SAMSUNG: 'SAMSUNG'
}
