import React, { useContext, useEffect, useState } from 'react'
import {
  MemoryRouter as Router, Switch, Route, Link,
} from 'react-router-dom'
import { useActions } from '../../Action'
import { store } from '../../Store'
import ReportSummary from './reportsummary'
import ReportingCareCreditReport from './carecreditreports'
import ReportDetails from './reportdetails'

const INVOKE_ONCE = true

const Reporting = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { Login, roles, ReportingDetailsInputFields } = state
  const { StoreAgentRole } = Login
  const ReportingRoles = roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs') && roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'Tabs')[0]
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase()

  /*   const careCreditReportTab = () => {
    actions.setReportDetailsTab(false)
    //actions.setReportCareCreditTab(true)
    actions.setReportSummaryTab(false)

  } */

  const reportSummaryTab = () => {
    actions.setReportDetailsTab(false)
    // actions.setReportCareCreditTab(false)
    actions.setReportSummaryTab(true)
  }

  const reportDetailsTab = () => {
    actions.setReportDetailsTab(true)
    // actions.setReportCareCreditTab(false)
    actions.setReportSummaryTab(false)

    const GetReportSummaryRequest = {
      // PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
      PartnerStoreId: '',
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,
      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.setReportSelectedPage(0)
    actions.getReportingDetails({ GetReportSummaryRequest })
  }

  const { reportingDetailsTab, reportingCareCreditTab, reportingSummaryTab } = state

  return (
    <Router>
      <div className="ml-50 mr-50 min-h-screen mb-100 font-AvenirNextRegular">
        <div className="flex items-center pt-30">
          <h2 className="text-4xl font-bold leading-37 p-1 text-dark-gray font-AvenirNextRegular">Reporting</h2>
          {ReportingRoles.ReportSummary[agentRole] === 'SHOW' && (
            <div className={`${reportingSummaryTab ? 'bg-lightBlue text-white' : 'bg-white text-lightBlue border border-lightBlue'} ml-30 font-AvenirNextRegular text-base cursor-pointer rounded-5 p-10`}>
              <Link
                to="/reportsummary"
                onClick={() => {
                  reportSummaryTab()
                }}
                className="cursor-pointer"
              >
                SUMMARY
              </Link>
            </div>
          )}
          {ReportingRoles.ReportDetails[agentRole] === 'SHOW' && (
            <div className={`${reportingDetailsTab ? 'bg-lightBlue text-white' : 'bg-white text-lightBlue border border-lightBlue'} ml-10 font-AvenirNextRegular text-base cursor-pointer rounded-5 p-10`}>
              <Link
                to="/reportdetails"
                onClick={() => {
                  reportDetailsTab()
                }}
                className="cursor-pointer"
              >
                DETAILS
              </Link>
            </div>
          )}
          {/* {ReportingRoles['CareCreditReport'][agentRole] === 'SHOW' && (
            <div className={`${reportingCareCreditTab ? 'text-red-1' : 'text-grey-3'} text-17 leading-22 text-center ml-m5 py-2 m-2 font-VodafoneRgBd`}>
              <Link
                to="/carecreditreport"
                onClick={() => {
                  careCreditReportTab()
                }}
                className="cursor-pointer"
              >
                Credit Report
              </Link>
            </div>
          )} */}
        </div>
        <Switch>
          <Route path="/reportdetails">
            <ReportDetails />
          </Route>
          <Route path="/reportsummary">
            <ReportSummary />
          </Route>
          {/*  <Route path="/carecreditreport">
            <ReportingCareCreditReport />
          </Route> */}
          <Route exact path="/">
            <ReportSummary />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default Reporting
