import React, { useContext } from 'react'
import LabeledInputField from '../utils/custom-fields'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../Action'
import { store } from '../Store'
import { useHistory } from 'react-router-dom'
import HNLogo from '../assets/trade-logo.png'
import FreedomLogo from "../assets/freedom_logo.png"
import footerLogo from '../assets/img-asurion.png';
import zxcvbn from 'zxcvbn'

const changePassword = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {},
  })
  const { isValid } = formState
  const { Login, forgotPasswordSuccessResponse, confirmPasswordError } = state
  const status = forgotPasswordSuccessResponse && forgotPasswordSuccessResponse.Status
  const confirmPassStatus = confirmPasswordError && confirmPasswordError.Status
  const confirmPassFailureMsg = confirmPassStatus ? confirmPasswordError && confirmPasswordError.Error : ''
  const resources = state && state.resources
  const invalidPassword = Login && Login.invalidPassword
  const newPassword = Login && Login.newPassword
  const currentPassword = Login && Login.currentPassword
  const confirmNewPassword = Login && Login.confirmNewPassword
  const passwordStatus = Login && Login.Status
  const CognitoError = Login && Login.CognitoError
  const isSubmitted = state && state.isSubmitted
  const verificationCode = Login && Login.verificationCode
  let ErrorMessage = ''
  let createPasswordLabel = (result) => {
    switch (result.score + 1) {
      case 1:
        return 'Too-Short';
      case 2:
        return 'Weak';
      case 3:
        return 'Fair';
      case 4:
        return 'Good';
      case 5:
        return 'Strong';
      default:
        return 'Too-Short';
    }
  }
  if (CognitoError && CognitoError.includes('Unable to find agent with Mail id', 0)) {
    ErrorMessage = 'Please contact your IT Administrator'
  } else {
    ErrorMessage = CognitoError
  }
  if (passwordStatus === 'success') {
    history.replace('/logistics')
  }
  if (newPassword === confirmNewPassword && newPassword !== currentPassword && invalidPassword === true) {
    actions.setInvalidPassword(false)
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    changePassword();
  }

  const changePassword = () => {
    // var CryptoJS = require("crypto-js");
    // var encryptedNewPassword = CryptoJS.AES.encrypt(Login && Login.newPassword, process.env.SECURITY_KEY).toString();

    // var encryptedCurrentPassword = CryptoJS.AES.encrypt(Login && Login.currentPassword, process.env.SECURITY_KEY).toString();

    if (status && status === 'Success') {
      const data = {
        username: Login && Login.email,
        verificationcode: Login && Login.verificationCode,
        newpassword: Login && Login?.newPassword,
      }
      if (newPassword === confirmNewPassword && verificationCode !== undefined) {
        actions.confirmPassword(data)
      } else {
        actions.setInvalidPassword(true)
      }
    } else {
      const data = {
        username: Login && Login?.email,
        newpassword: Login && Login?.newPassword,
        oldpassword: Login && Login?.currentPassword,
      }
      if (newPassword === confirmNewPassword && newPassword !== currentPassword) {
        actions.changePassword(data)
      } else {
        actions.setInvalidPassword(true)
      }
    }

  }
  let testedResult;
  if (Login.newPassword) {
    testedResult = zxcvbn(Login.newPassword);
  }

  return (
    <>
      <div className="bg-img1">
        <form onSubmit={handleSubmit} className="container">
          <div className="flex flex-row">
                    <img className="h-40" src={HNLogo} alt="trade-img" />
                    <img className="h-40 ml-30" src={FreedomLogo} alt="asurion-img" />
          </div>
          <div className="flex-col inline-flex">
          <p className="text-textBlack font-AvenirNextRegular font-semibold mt-90 text-3xl">How to reset your password</p>
            {/* <p className="text-17 leading-28 font-AvenirNextRegular font-bold mt-20">Password requirements</p> */}
            <p className="text-base  font-AvenirNextRegular mt-20">At least one lower case letter [a-z]</p>
            <p className="text-base  font-AvenirNextRegular ">At least one upper case letter [A-Z]</p>
            <p className="text-base  font-AvenirNextRegular ">At least one numeral [0-9]</p>
            <p className="text-base  font-AvenirNextRegular ">At least one symbol [@!#$%&]</p>
            <p className="text-base  font-AvenirNextRegular ">Minimum of 8 characters</p>
          </div>
          <div className='mt-40 flex flex-row'>
          <div className="flex flex-col">
            {status && status === 'Success' ?
              <LabeledInputField
                name="verificationCode"
                type="text"
                labelClass="ml-3 text-sm w-150 inline-block"
                label="VODAFONE_LBL_VERFICATION_CODE"
                placeholder=""
                className="mt-1 w-200 h-50"
                onChange={actions.setVerificationCode}
                register={register}
                errors={errors}
                schema="verification"
                defaultValue={Login && Login.verificationCode}
              /> :
              <LabeledInputField
                name="currentPassword"
                type="password"
                labelClass="mr-10 text-xs w-150 inline-block"
                label="VODAFONE_CURRENT_PASSWORD_LABEL"
                placeholder=""
                className="mt-1 w-200 h-50"
                onChange={actions.setCurrentPassword}
                register={register}
                errors={errors}
                schema="verification"
                defaultValue={Login && Login.currentPassword}
                fromForgotPassword={true}
                passwordIconClass={{left: '86%', top:'18px'}}
              />
            }
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col">
              <LabeledInputField
                name="newPassword"
                type="password"
                labelClass="mr-10 ml-20 text-xs w-150 inline-block"
                label="VODAFONE_NEW_PASSWORD_LABEL"
                placeholder=""
                className="ml-20 mt-1 w-200 h-50"
                onChange={actions.setNewPassword}
                register={register}
                errors={errors}
                //schema="verification"
                defaultValue={Login && Login.newPassword}
                fromForgotPassword={true}
                passwordIconClass={{left: '86%', top:'18px'}}
              />
            </div>
            
          </div>
          <div className="flex flex-row">
              <div className="flex flex-col">
                <LabeledInputField
                  name="confirmNewPassword"
                  type="password"
                  labelClass="mr-10 ml-20 text-xs w-200 inline-block"
                  label="VODAFONE_CONFIRM_NEW_PASSWORD_LABEL"
                  placeholder=""
                  className="ml-20 mt-1 w-200 h-50 mr-20"
                  onChange={actions.setConfirmNewPassword}
                  register={register}
                  errors={errors}
                  //schema="verification"
                  defaultValue={Login && Login.confirmNewPassword}
                  fromForgotPassword={true}
                  passwordIconClass={{left: '80%', top:'18px'}}
                /></div>
            {Login.newPassword &&
              <div className="password-strength-meter flex flex-col ml-4 self-center">
                <p id="password-strength-text text-dark-gray">Strength<span className="font-bold">{` ${testedResult.score === 0 ? 'Too Short' : createPasswordLabel(testedResult)}`}</span></p>
                <progress
                  className={`mt-2 password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
                  value={testedResult.score + 1}
                  max="5"
                />
              </div>
            }
          </div>
          </div>

          <div className="flex flex-row">
                <button
                  onClick={()=>{
                    history.replace('/login')
                  }}
                  style={{padding: "12px 24px", marginLeft: 0, width: 'auto', height:'auto' }}
                  className={`text-white font-bold text-sm rounded-6 btn-search bg-textPurple`}>
                  CANCEL
                </button>
                <button
                  disabled={invalidPassword || !isValid}
                  style={{padding: "12px 32px", marginLeft: '40px', width: 'auto', height:'auto' }}
                  type="submit"
                  className={`font-bold text-sm ${invalidPassword || !isValid ? 'bg-textPurple' : 'bg-HnSecondaryAmethyst'} text-white rounded-6 btn-search`}>
                  SUBMIT
                </button>
          </div>
        
          {
                (invalidPassword || passwordStatus === 'failure' || confirmPassStatus === 'failure' || confirmPassStatus === 'account_lock') && isSubmitted && (
                  <div className="flex flex-row ml-340">
                    <p className="error-text">
                      {newPassword !== confirmNewPassword
                        ? 'Password is not matching'
                        : newPassword === currentPassword
                          ? 'New password can not be same as current password'
                          : passwordStatus === 'failure'
                            ? ErrorMessage
                            : confirmPassFailureMsg ?
                              confirmPassStatus === 'account_lock' ? "Too many failed attempts. Your account has been locked, please try again in 10 minutes." : confirmPassFailureMsg :
                              ''
                      }
                    </p>
                  </div>
            )}
          <div className="flex flex-col">
            <img className="img-footer w-24" src={footerLogo} alt="asurion" />
          </div>
        </form>
      </div>
    </>
  )
}
export default changePassword