import React from 'react';
import MFCostTable from '../common/feetable';

export const FreedomFAQs=[
    {
        question: 'What is Mobile Freedom?',
        answer: (
          <p>
            <p className={'text-sm'}>
            Mobile Freedom is a 12-month subscription for your eligible enrolled device giving you access to the following benefits:
            </p>
            <ul className="list-disc my-2 ml-4">
              <li>
                <p className="text-sm">
                  <span className="font-bold">Unlimited Mobile Refresh:</span> Includes a genuine OEM screen replacement, a device accessory kit (including standard wired headset and charge cable), a detailed device clean and a diagnostic test & reset.
                </p>
              </li>
              <li>
                <p className="text-sm">
                  <span className="font-bold">One Mobile Swap:</span> You are eligible to swap your
                  device once, for any reason{' '}
                </p>
              </li>
              <li>
                <p className="text-sm">
                  <span className="font-bold">One Mobile Upgrade:</span> You are eligible to upgrade
                  your device to the next generation model (if available), for any reason,after the first 30 days of your subscription*.
                </p>
              </li>
            </ul>
            <p
              className={'text-sm mt-2'}
            >
                Once you have used your Upgrade entitlement, your subscription will terminate and any unused benefits will be forfeited.
            </p>
            <p
              text={''}
              className={'text-sm mt-2'}
            >
            A Service Request Fee applies each time you make a Service Request.
            </p>
            <p  
              className={'text-sm my-2'}
            >
            A Mobile Freedom subscription is only available to be purchased at the point of sale from participating Harvey Norman®, Domayne® and Joyce Mayne® franchisee stores with your new eligible device.
            </p>
            <MFCostTable 
             borderClasses={'border border-black'}
             headerClasses={'bg-HnPrimaryAmethyst text-white italic'}
             padding={'p-2 text-left'}
             />
          </p>
        )
    },
    {
        question: "How can a customer purchase a Mobile Freedom subscription?",
        answer: 'Mobile Freedom subscription can only be purchased together with a new device from participating Harvey Norman®, Domayne® and Joyce Mayne® franchisee stores.'
    },
    {
        question: "Can a customer purchase a Mobile Freedom subscription at a later date?",
        answer: 'No. A Mobile Freedom subscription can only be purchased together with a new device from participating Harvey Norman®, Domayne® and Joyce Mayne® franchisee stores.'
    },
    {
        question: "How does a customer request a Mobile Upgrade?",
        answer: (
            <p>
              <p className={'text-sm'}>
              A customer can request a Mobile Upgrade after the first 30 days of their subscription and if the next generation model of their enrolled device has been released in the Australian market. An Upgrade Request must be started in the Mobile Freedom Portal and then finalised in-store. An Upgrade Fee is applicable when the customer requests a Mobile Upgrade. The customer will be issued with an Upgrade ID via email which they will need to take into a participating Harvey Norman®, Domayne® or Joyce Mayne® franchisee store within 7 days to collect their Upgrade device and to hand over their original device. The store must take custody of the original device and arrange for it to be delivered to Asurion on the customer’s behalf. 
              </p>
              <p
                className={'text-sm mt-2'}
              >
              If the customer does not use their Upgrade ID within 7 days it will expire and the Upgrade Fee will be refunded to them. If it has expired, they can log in to the Mobile Freedom Portal and make a new Upgrade Request. 
              </p>
              <p
                text={''}
                className={'text-sm mt-2'}
              >
               Once the customer has completed an Upgrade, their subscription will terminate and any used entitlements will be forfeited.
              </p>
            </p>
          )
    },
    {
        question: "What is the customer eligible to upgrade to?",
        answer: 'The customer is only eligible to upgrade to the next generation model of their enrolled device. For example, a customer with an iPhone 13 512GB can upgrade to the iPhone 14 512GB. This information will be configured in the backend system by Asurion.'
    },
    {
        question: "What do I do with the Upgrade ID?",
        answer: 'When a customer has successfully submitted an Upgrade Service Request, they will be issued with an Upgrade ID. You will need to enter the Upgrade ID into the Mobile Freedom Store Portal. The Upgrade ID will expire after 7 days of issue.'
    },
    {
        question: 'What do I do once I have verified the Upgrade ID?',
        answer: (
          <p>
            <p className={'text-sm'}>
            You will need to perform a number of checks on the device being handed in once you have verified the Upgrade ID and prior to providing the customer with their Upgrade device. The following needs to be verified in the Portal before the customer can receive their Upgrade device:
            </p>
            <ul className="list-none my-2 ml-4">
              <li>
                <p className="text-sm">
                -	Device is unlocked and security locks disabled (including Find My for Apple devices)
                </p>
              </li>
              <li>
                <p className="text-sm">
                -	IMEI, make and model matches the enrolled device in the system
                </p>
              </li>
              <li>
                <p className="text-sm">
                -	There is available stock of the Upgrade device
                </p>
              </li>
            </ul>
            <p  
              className={'text-sm mt-2'}
            >
            Note – You will not be able to generate the Upgrade QR Code if the device is security locked or the device IMEI does not match.
            </p>
          </p>
        )
    },
    {
        question: "What is the Upgrade QR Code used for?",
        answer: 'After relevant checks are completed, you are able to generate an Upgrade QR Code for the customer. This will be instantly emailed to the customer and also displayed in the Portal. This QR Code is required for scanning into the POS system to release the Upgrade device to the customer.'
    },
    {
        question: "How do I send the original device back to Asurion?",
        answer: 'The original device from the customer will need to be sent back to Asurion using the provided packaging and can be batched via the Logistics tab. In the Logistics tab you can generate the batching label and arrange for pickup. This process is the same as for Trade In. Please note that Asurion requires devices to be returned as soon as possible. You must dispatch devices to Asurion either when you have taken custody of at least 5 registered devices, or 5 business days after an Upgrade fulfilment (whichever is the earlier).'
    },
    {
        question: "Is Trade In and Mobile Freedom the same?",
        answer: 'No, although both programs are run by Asurion, they are not the same.'
    },
    {
        question: "Troubleshooting",
        answer: 'If you require additional help, please reach out to your local Regional Account Manager.'
    }
]