import React, { useContext, useEffect, useState } from 'react'
import { store } from '../../Store'
import Button from '../common/button'
import Checkbox from '../common/checkbox'
import HelpActive from '../../assets/question_purple.png'
import moment from 'moment'
import { useActions } from '../../Action'
import { DeviceMakes, TradeStatus } from '../../helper/AppConstants'

const UpgradeDetails = [
  {
    key: 'IMEI or Serial Number ',
    id: 'IMEI',
    getData: (tradeDetails) => tradeDetails?.IMEI || 'NA'
  },
  {
    key: 'Trade ID Expiry Date',
    id: 'OfferExpiryDate',
    getData: (tradeDetails) => moment.utc(tradeDetails?.OfferExpiryDate).format('DD MMMM YYYY')
  },
  {
    key: 'Device to Return',
    id: 'EnrolledAsset',
    getData: (tradeDetails) => tradeDetails?.TradeDevice
  },
  {
    key: 'Trade-in Value',
    id: 'UpgradeAsset',
    getData: (tradeDetails) => `$${tradeDetails?.FinalPrice}`
  },
]
const ConfirmCheckList = [
  {
    title: 'Customer has unlocked device and disabled security features.',
    key: 'security_lock',
  },
  {
    title: 'Confirmed that the IMEI or serial number is correct.',
    key: 'imei',
  }
]

const UpgradeDetailsAndChecklist = ({  setShowCustomerInfoModal, setShowQRCode }) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { tradeDetails } = state
  const [checklist, setChecklist] = useState([])

  const handleChecklist = (key) => {
    let data = [...checklist]
    if (!data.includes(key)) {
      data.push(key)
    } else {
      data.splice(data.indexOf(key), 1)
    }
    setChecklist(data)
  }

  const verifyFmip = () => {
    const imeiRequest = [
      {
        os: tradeDetails?.Make.toUpperCase() === DeviceMakes.APPLE ? 'ios' : tradeDetails?.Make,
        imei: tradeDetails?.IMEI,
      },
    ]
    actions.verifyImei(imeiRequest)
  }

  useEffect(() => {
    // set checklist with all values if TradeInStatus is not OPEN
    if (tradeDetails?.TradeInStatus !== TradeStatus.OPEN) {
      setChecklist(['security_lock', 'imei'])
    }
  }, [])

  // useEffect(() => {
  //     setShowCustomerInfoModal(true)
  // }, [tradeDetails])

  const goBackToSearch = () => {
    actions.clearTradeDetails()
  }

  const handleGenerateQrCode = () => {
    // show QR Code if TradeInStatus is not OPEN, or call verifyImei
    if (tradeDetails?.TradeInStatus !== TradeStatus.OPEN) {
      setShowQRCode(true);
      actions.setIsTradeSubmitted(true)
    } else {
      // if(tradeDetails?.Make?.toLowerCase() === 'apple')
      // {
      //   verifyFmip()
      // }
      // else{
        setShowCustomerInfoModal(true);
      // }     
    }
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-center w-full mt-30">
        <div className="flex flex-col w-full max-w-md">
          <p className="font-bold text-lg">Device Details</p>
          {UpgradeDetails?.map((item, index) => {
            return (
              <div key={item?.key} className="flex py-10 border-border" style={{ borderBottomWidth: index + 1 !== UpgradeDetails?.length && '1px' }}>
                <div className="w-200 text-lg text-textColor">{item?.key}</div>
                <div className="w-240 font-bold text-lg">{item.getData(tradeDetails)}</div>
              </div>
            )
          })}
        </div>
        {/* <div className="flex flex-col ml-40">
          <p className="font-bold">Checklist</p>
          {ConfirmCheckList?.map((item, index) => {
            return (
              <div className="flex flex-row py-10 border-border items-center mb-10" style={{ borderBottomWidth: index + 1 !== ConfirmCheckList?.length && '1px' }}>
                <Checkbox isChecked={checklist?.includes(item?.key)} onChange={() => handleChecklist(item?.key)} disabled={tradeDetails?.TradeInStatus !== TradeStatus.OPEN} />
                <div className=" text-sm pl-10">{item?.title}</div>
                {['security_lock']?.includes(item?.key) && (
                  <div className="tooltipPurple">
                    <div className="flex flex-row ml-2">
                      <img src={HelpActive} className="tooltipPurple cursor-pointer object-contain h-4" />
                    </div>
                    <span className="tooltipPurpleText  font-normal  text-sm" style={{ marginLeft: '-141px' }}>
                      FMIP and Google locks or PIN/Passcode must be removed and disabled.
                    </span>
                  </div>
                )}
                {['imei']?.includes(item?.key) && (
                  <div className="tooltipPurple">
                    <div className="flex flex-row ml-2">
                      <img src={HelpActive} className="tooltipPurple cursor-pointer object-contain h-4" />
                    </div>
                    <span className="tooltipPurpleText  font-normal  text-sm" style={{ marginLeft: '-141px' }}>
                      <p>Look at the back of your device, or dial the universal code *#06# or check at the sim tray.</p>
                      <p>The IMEI is typically a 15 digit number starting with the number 3.</p>
                    </span>
                  </div>
                )}
              </div>
            )
          })}
        </div> */}
      </div>
      <div className="flex flex-row my-30 items-center justify-center">
        <Button onClick={goBackToSearch} otherClass="text-sm bg-white border border-lightBlue" textClass="text-lightBlue" disabled={false} ignoreDisable={true} label="GO BACK" padding="px-22 py-10" />
        <Button
          otherClass="active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5"
          label="GENERATE QR CODE"
          padding="px-14 py-11"
          onClick={handleGenerateQrCode}
          disabled={false}
        />
      </div>
    </div>
  )
}

export default UpgradeDetailsAndChecklist
