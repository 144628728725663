import React, { Component } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useContext, useEffect, useState } from 'react'

const ReportDetails = (props) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)

  const { reportingData, osotReportData, Login, roles } = state
  const data = osotReportData && osotReportData.GetOSOTReportDetails
  const { StoreAgentRole } = Login
  // tradeid click role access
  const ReportingClickRoles = roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'ReportClickDetails') && roles && roles.filter((r) => r.menu === 'Reporting' && r.section === 'ReportClickDetails')[0]
  const agentclickRole = StoreAgentRole && StoreAgentRole.toUpperCase()
  return (
    <div>
      {data &&
        data.map(
          (item, index) =>
            item.isSelected && (
              <table key={index} className="mt-25 border border-solid  text-sm border border-black-2 bg-white">
                <tbody>
                  <tr className="bg-white">
                    {ReportingClickRoles['TradeId'][agentclickRole] === 'SHOW' ?
                      (
                        <td className="w-370 h-50 pl-13 border">
                          <span className="font-bold text-dark-gray">Trade-In ID </span> : {item.TradeId}
                        </td>
                      ) : (<td className="w-370 h-50 pl-13 border" />)}
                    {ReportingClickRoles['JobType'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Job Type </span>:&nbsp; {item.JobType}
                      </td>) : <td className="w-370 h-50 pl-13 border" />}
                    {ReportingClickRoles['DateReceivedAtWarehouse'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Date Received At Warehouse </span>:&nbsp; {item.DateReceivedAtWarehouse}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['StoreCode'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Create by Code </span>:&nbsp; {item.StoreCode}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIIn'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> IMEI/Serial No. IN </span>:&nbsp; {item.IMEIIn}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Total Credit </span>:&nbsp; {item.TotalCredit}
                    </td>
                    {ReportingClickRoles['DateAssessed'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Date Assessed </span>:&nbsp; {item.DateAssessed}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> App Condition </span>:&nbsp; {item.AppCondition}
                    </td>
                    {ReportingClickRoles['StoreName'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Created by Name </span>:&nbsp; {item.StoreName}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIInManufacturer'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> IMEI/Serial No. IN Manufacture </span>:&nbsp; {item.IMEIInManufracturer}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Credit Application Period </span>:&nbsp; {item.CreditApplicationPeriod}
                    </td>
                    {ReportingClickRoles['AssessmentOutcome'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Assessment Outcome </span>:&nbsp; {item.AssessmentOutcome}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Graded Condition </span>:&nbsp; {item.GradedCondition}
                    </td>
                    {ReportingClickRoles['TeamMemberName'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray">Team Member Name </span>  : {item.TeamMemberName}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIInModel'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> IMEI/Serial No. in Model/Variant </span>:&nbsp; {item.IMEIInModel}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    {ReportingClickRoles['BatchNbr'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Batch Number </span>:&nbsp; {item.BatchNumber}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['FaultCode'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Fault Code </span>:&nbsp; {item.FaultCode}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Graded Price </span>:&nbsp; {item.GradedPrice}
                    </td>
                    {ReportingClickRoles['TeamMemberId'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Team Member ID </span> : {item.TeamMemberID}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIOut'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> IMEI/Serial No. Out </span>:&nbsp; {item.IMEIOut}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    {ReportingClickRoles['ConsignmentDetails'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Consignment Details </span>:&nbsp; {item.ConsignmentDetails}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['FaultReason'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Fault Reason </span>:&nbsp; {item.FaultReason}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Raised Location </span>:&nbsp; {item.RaisedLocation}
                    </td>
                    {ReportingClickRoles['CurrentJobStatus'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Current Job Status </span>:&nbsp; {item.CurrentJobStatus}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIOutManufacturer'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray">IMEI/Serial No. Out Manufacture </span> : {item.IMEIOutManufracturer}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    {ReportingClickRoles['ValidationRequired'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Validation Required Y/N </span>:&nbsp; {item.ValidationRequired}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['DaysPendingInStore'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Days Pending in Store </span>:&nbsp; {item.DaysPendingInStore}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['CurrentLocation'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Current Location </span>  : {item.CurrentLocation}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['IMEIOutModel'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> IMEI/Serial No. Out Model/Variant </span>:&nbsp; {item.IMEIOutModel}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['DateCreated'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Date Created </span>:&nbsp; {item.DateCreated}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    {ReportingClickRoles['DaysPendingInTransit'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Days Pending in Transit </span>:&nbsp; {item.DaysPendingInTransit}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Region </span>:&nbsp; {item.Region}
                    </td>
                    {ReportingClickRoles['CustomerPhoneMobile'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Customer Phone Mobile </span> : {item.CustomerPhoneMobile}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['QuotePrice'][agentclickRole] === 'SHOW' ? (
                      <td className="w-370 h-50 pl-13 border">
                        <span className="font-bold text-dark-gray"> Quote Price </span>:&nbsp; {item.QuotePrice}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['DateBatched'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Date Batched </span>:&nbsp; {item.DateBatched}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                  </tr>
                  <tr className="bg-white">
                    {ReportingClickRoles['DaysPendingAssessment'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Days Pending Assessment </span>:&nbsp; {item.DaysPendingInAssessment}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Trading Name </span>:&nbsp; {item.TradingName}
                    </td>
                    {ReportingClickRoles['DatePickUp'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Date Pick Up or Lodge </span>:&nbsp; {item.DatePickupLodged}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    {ReportingClickRoles['OverAllAging'][agentclickRole] === 'SHOW' ? (
                      <td className="h-50 w-370 pl-13 border">
                        <span className="font-bold text-dark-gray"> Overall Aging </span>:&nbsp; {item.OverallAging}
                      </td>) : <td className="h-50 w-370 pl-13 border" />}
                    <td className="h-50 w-370 pl-13 border">
                      <span className="font-bold text-dark-gray"> Reporting State </span>:&nbsp; {item.ReportingState}
                    </td>
                  </tr>
                </tbody>
              </table>
            )
        )}
    </div>
  )
}

export default ReportDetails
