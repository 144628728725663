import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Modal from '../common/modal'
import Button from '../common/button'
import { store } from '../../Store'

const customerDetails = [
  {
    key: 'First Name',
    id: 'FirstName',
  },
  {
    key: 'Last Name',
    id: 'LastName',
  },
  {
    key: 'Email Address',
    id: 'EmailAddress',
  },
  {
    key: 'Mobile Number',
    id: 'PhoneNbr',
  },
]

const CustomerInfoModal = ({ open, setOpen, handleUserInfoModal }) => {
  const { state } = useContext(store)
  const { tradeDetails } = state

  return (
    <Modal open={open} setOpen={setOpen} showClose={true} containerClasses={'p-30 rounded-2xl'}>
      <div className="rounded-t-20">
        <div className="flex flex-col w-full">
          <p className="font-bold">Customer Details</p>
          {customerDetails?.map((item, index) => {
            return (
              <div key={item?.key} className="flex flex-row justify-between py-10 border-border" style={{ borderBottomWidth: index + 1 !== customerDetails?.length && '1px' }}>
                <div className=" text-sm text-textColor" style={{ width: '50%', flex: '0.5' }}>
                  {item?.key}
                </div>
                <div className="font-bold  text-sm" style={{ width: '50%', flex: '0.5' }}>
                  {tradeDetails?.[item?.id] || 'NA'}
                </div>
              </div>
            )
          })}
        </div>
        <div className="pt-15 items-center justify-center flex">
          <Button
            onClick={() => {
              setOpen(false)
            }}
            otherClass={'text-sm bg-white border border-lightBlue'}
            textClass={'text-lightBlue'}
            disabled={false}
            ignoreDisable={true}
            label={'Cancel'}
            padding="px-30 py-11"
          />
          <Button
            otherClass={'active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5'}
            label={'Submit'}
            padding="px-32 py-11"
            onClick={() => {
              handleUserInfoModal()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

CustomerInfoModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  customerDetails: PropTypes.array,
  handleUserInfoModal: PropTypes.func,
}
export default CustomerInfoModal
