import React, { Component, useContext, useEffect } from 'react'
import { MemoryRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { store } from './Store'
import { useActions } from './Action'
import Header from './components/header'
import Footer from './components/footer'
import Login from './components/login'
import Loader from './components/loader'
import ChangePassword from './components/change-password'
import Logistics from './components/logistics/logistics'
import Reporting from './components/reports/reports'
import ForgotPassword from './components/forgot-password'
import ContactUs from './components/contactus/contactus'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { Config } from './config'
import Upgrade from './components/upgrade/upgrade'
import TradeIn from './components/tradein/upgrade'
import SelectOption from "./components/select-option"
import './appStyles.css'

const GTMid = process.env.GTMid
const tagManagerArgs = {
  gtmId: GTMid,
}

const INVOKE_ONCE = true

const App = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const changeHeader = state && state.loginsuccess
  const resources = state && state.resources
  const _isLoading = state && state.isLoading
  const OptionsPage = state && state.OptionsPage
  const UpgradeTab = state && state.UpgradeTab
  const isSubmitLoader = state && state.submitLoader
  TagManager.initialize(tagManagerArgs)
  useEffect(() => {
    actions.getResources()
    //actions.getMasterdata()
  }, [INVOKE_ONCE])

  return (
    <React.Fragment key="route">
      <Helmet>
        <meta name="robots" content={Config.APP.isProd ? 'index,follow' : 'noindex, nofollow'} />
        {!Config.APP.isProd && <meta name="googlebot" content="none" />}
      </Helmet>
      {_isLoading && <Loader />}
      {resources && (
        <div className={`${UpgradeTab ? 'tabletBackgroundImg_Upgrade desktopBackgroundImg_Upgrade loginBackgroundImg_Upgrade backgroundImg_Upgrade' : 'tabletBackgroundImg desktopBackgroundImg loginBackgroundImg backgroundImg'}`}>
          <Router>
            {changeHeader && !OptionsPage && <Header />}
            <Switch>
              <Route path="/changepass">
                <ChangePassword />
              </Route>
              <Route path="/forgotpass">
                <ForgotPassword />
              </Route>
              <Route path="/logistics">
                <Logistics />
              </Route>
              <Route path="/upgrade">
                <Upgrade />
              </Route>
              <Route path="/tradein">
                <TradeIn />
              </Route>
              <Route path="/select">
                <SelectOption />
              </Route>
              <Route path="/reports">
                <Reporting />
              </Route>
              <Route path="/contactus">
                <ContactUs />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
            {changeHeader &&  !OptionsPage && !UpgradeTab && <Footer />}
          </Router>
        </div>
      )}
    </React.Fragment>
  )
}
export default App
