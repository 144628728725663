import React from 'react'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, SortSelection, DateInputField } from '../../utils/custom-fields'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginate from '../pagination'
import ReportingDetails from './details'
import FileSaver from 'file-saver';
import ExportColumns from './exportreportformat';
import StatusNames from '../../dummyjson/reportStatus.json';
const moment = require('moment')

const ReportDetails = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { ReportingDetailsInputFields, resources, reportSelectedPage, reportingData, Login, shoeReportingDetails,roles } = state
  const ReportDetails = reportingData && reportingData.ReportDetails
  const TotalRecords = reportingData && reportingData.TotalRecords

  const { register, errors, setValue } = useForm({ mode: 'onChange' })
  const careRole = Login && Login.StoreAgentRole && Login.StoreAgentRole.toUpperCase() === 'CARE'
  const baseURL = 'https://msto.startrack.com.au/track-trace/?id='
  const baseAuspostURL = 'https://auspost.com.au/mypost/track/#/details/'
  const exportServiceCall = () => {
    let GetReportSummaryRequest = {
      //PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
      PartnerStoreId: "",
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,
      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: TotalRecords,
      PageNumber: 1,
    }
    actions.getReportingDetails({ GetReportSummaryRequest},true)
  }
 /*  const exportFunction = () => {
    const exportColumns = [];
    const exportData = [];
    const header = [];
    exportServiceCall()
    ExportColumns.forEach(col => {
      const colHeading = col.header;
      header.push(colHeading);
      exportColumns.push(col);
    })

    exportData.push(header);

    ReportDetails.forEach(item => {
      const row = [];
      exportColumns.forEach(col => {
        row.push(item[col.key] || '');
      })

      exportData.push(row);
    })

    const csvData = exportData.reduce((csv, row) => {
      csv += row.join(',') + '\r\n';
      return csv;
    }, '');

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, `MasterViewReport.csv`);
    return true;
  } */


  let enableExportButton = ReportDetails && ReportDetails.length > 0 ? true : false;

  useEffect(() => {
    searchReport()
  }, [reportSelectedPage])

  const pageLimit = 10
  const totalPages = Math.ceil(TotalRecords / pageLimit)
  const range = (from, to, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
      range.push(i)
      i += step
    }
    return range
  }
  let pages = range(1, totalPages)
  const [offset, setOffset] = useState(0)
  let [details, setData] = ReportDetails ? useState(ReportDetails) : useState([])
  const [reportCurrentData, setReportCurrentData] = useState([])
  //let selectedBatch = reportCurrentData && reportCurrentData.filter(details => details.isSelected === true)[0]
  useEffect(() => {
    setReportCurrentData(details.slice(offset, offset + pageLimit))
  }, [offset, details])

  let status = StatusNames && StatusNames.map (i => ({key: i.key, value: i.value}));

  useEffect(() => {
    return () => {
      actions.setReportTradeIDNumber(undefined)
      actions.setReportStartDate(undefined)
      actions.setReportEndDate(undefined)
      actions.setReportState(undefined)
      actions.setReportStatus(undefined)
      actions.setReportStoreName(undefined)
      actions.setReportSelectedPage(0)
      actions.setOsotReportData({})
    }
  }, [])

  const searchItem = (tradeid, flag) => {
    ReportDetails &&
      ReportDetails.map((item) => {
        if (item.TradeId === tradeid && !flag) {
          item.isSelected = true
          actions.setShowReportingDetails(true)
        }
        if (flag) {
          actions.setShowReportingDetails(false)
          item.isSelected = false
        }
        return item
      })
    actions.setOsotReportData(ReportDetails)
  }

  const searchReport = (isSearch = false) => {
    if (isSearch) {
      actions.setReportSelectedPage(0)
    }
    let GetReportSummaryRequest = {
      //PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
      PartnerStoreId: "",
      TradeQuoteNbr: ReportingDetailsInputFields.Tradeid,
      StartDate: ReportingDetailsInputFields.StartDate,
      EndDate: ReportingDetailsInputFields.EndDate,
      State: ReportingDetailsInputFields.State,
      Status: ReportingDetailsInputFields.Status,
      StoreName: ReportingDetailsInputFields.StoreName,
      PageSize: 10,
      PageNumber: isSearch ? 1 : reportSelectedPage + 1,
    }
    actions.getReportingDetails({ GetReportSummaryRequest })
  }

  const setReportSelectedPage = (index) => {
    actions.setReportSelectedPage(index)
  }

  const resetFields = () => {
    actions.setReportTradeIDNumber(undefined)
    actions.setReportStartDate(undefined)
    actions.setReportEndDate(undefined)
    actions.setReportState(undefined)
    actions.setReportStatus(undefined)
    actions.setReportStoreName(undefined)
    if (reportSelectedPage == 0) {
      let GetReportSummaryRequest = {
        PartnerStoreId: "",
        PageSize: 10,
        PageNumber: 1,
      }
      actions.getReportingDetails({ GetReportSummaryRequest })
    }
    actions.setReportSelectedPage(0)
    setValue('tradeID', '')
    setValue('date', '')
    setValue('date', '')
    setValue('reportState', '')
    setValue('reportStatus', '')
    setValue('storeName', '')
  }

  let pageCount = Math.ceil(TotalRecords / pageLimit)

  const ExportClickDetails = roles && roles.filter(r => r.menu === 'Reporting' && r.section === "ExportClickDetails") &&
    roles && roles.filter(r => r.menu === 'Reporting' && r.section === "ExportClickDetails")[0];
	const { StoreAgentRole } = Login;
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();

  return (
    <div>
      <div className="flex w-full items-center">
        <h2 className="text-17 font-AvenirNextRegular text-dark-gray mt-31 w-400 font-semibold mb-4">Search</h2>
      </div>
      <div className="inline-flex">
        <LabeledInputField
          className="order-no-input search-font"
          type="text"
          name="tradeID"
          schema="reporting"
          placeholder="REPORTING_LBL_TRADE_ID"
          onChange={actions.setReportTradeIDNumber}
          register={register}
          errors={errors}
          hideLabel
        />

        {/* <LabeledInputField
          className="report-date-input report-search-font"
          type="date"
          register={register}
          errors={errors}
          placeholder="REPORTING_LBL_START_DATE"
          onChange={actions.setReportStartDate}
          name="reportStartDate"
          schema="reporting"
          hideLabel
          value={ReportingDetailsInputFields ? ReportingDetailsInputFields.StartDate : ''}
        /> */}

        <DateInputField
          onChange={actions.setReportStartDate}
          placeholder="REPORTING_LBL_START_DATE"
          value={ReportingDetailsInputFields && ReportingDetailsInputFields.StartDate ? ReportingDetailsInputFields.StartDate : ''}
          format="dd-MM-yyyy"
        />

        {/* <LabeledInputField
          className="report-date-input report-search-font"
          type="date"
          register={register}
          errors={errors}
          placeholder="REPORTING_LBL_END_DATE"
          onChange={actions.setReportEndDate}
          name="reportEndDate"
          schema="reporting"
          hideLabel
          value={ReportingDetailsInputFields ? ReportingDetailsInputFields.EndDate : ''}
        /> */}

        <DateInputField
          onChange={actions.setReportEndDate}
          placeholder="REPORTING_LBL_END_DATE"
          value={ReportingDetailsInputFields && ReportingDetailsInputFields.EndDate ? ReportingDetailsInputFields.EndDate : ''}
          format="dd-MM-yyyy"
        />

        {/* <LabeledInputField
            className="report-input report-search-font"
            type="text"
            name="reportState"
            schema="reporting"
            placeholder="REPORTING_LBL_STATE"
            onChange={actions.setReportState}
            register={register}
            errors={errors}
            hideLabel
          />  */}

        {/* <LabeledInputField
          className="order-no-input search-font"
          type="text"
          name="reportStatus"
          schema="reporting"
          placeholder="REPORTING_LBL_STATUS"
          onChange={actions.setReportStatus}
          register={register}
          errors={errors}
          hideLabel
        /> */}

        <RenderSelectField
          name="reportStatus"
          label=""
          className="report-details-margin order-no-input search-font"
          register={register}
          onChange={(e) => actions.setReportStatus(e)}
          options={status}
          optionsKey="key"
          optionsValue="value"
          errors={errors}
          schema="reporting"
          value={ReportingDetailsInputFields ? ReportingDetailsInputFields.Status : ''}
        />

        <LabeledInputField
          className="order-no-input search-font"
          type="text"
          name="storeName"
          schema="reporting"
          placeholder="REPORTING_LBL_STORE_NAME"
          onChange={actions.setReportStoreName}
          register={register}
          errors={errors}
          hideLabel
        />

        <button className="font-AvenirNextRegular text-base cursor-pointer rounded-6 py-7 px-24 bg-lightBlue text-white" onClick={() => searchReport(true)}>
          {resources.HARVEY_NORMAN_SEARCH_BUTTON}
        </button>
        <button className="font-AvenirNextRegular text-base cursor-pointer rounded-6 py-7 px-24 bg-white text-lightBlue border border-lightBlue ml-15" onClick={() => resetFields()}>
          {resources.HARVEY_NORMAN_RESET_BUTTON}
        </button>
      </div>
      <div className="float-right">
        {!shoeReportingDetails ? (
          ExportClickDetails['Export'][agentRole] === 'SHOW' && (
            <button
              className={`font-AvenirNextRegular text-base cursor-pointer rounded-6 text-white py-7 px-24 ${!enableExportButton && ExportClickDetails['Export'][agentRole] === 'SHOW' ? 'bg-grey-3' : 'bg-textPurple'}`}
              onClick={() => exportServiceCall()}
              disabled={!enableExportButton}
            >
              EXPORT
            </button>
          )
        ) : (
          <button className="blue-button summary-btn ml-auto justify-between" onClick={() => searchItem('', true)}>
            {'SUMMARY PAGE'}
          </button>
        )}
      </div>

      {ReportDetails && ReportDetails.length > 0 ? (
        shoeReportingDetails ? (
          <div>
            <ReportingDetails />
          </div>
        ) : (
          <table className="mt-15 text-sm w-full h-50">
            <thead className="uppercase bg-textPurple text-white h-50 text-left font-AvenirNextRegular">
              <tr>
                <th className="leading-17 pl-13 w-80 h-34 rounded-tl-lg" colSpan="1">
                  Trade id
                </th>
                <th className="w-180 pl-13 " colSpan="1">
                  Device IMEI/Serial No.
                </th>
                <th className="w-250 pl-13" colSpan="1.5">
                  Make & Model
                </th>
                <th className="w-120 pl-13" colSpan="3">
                  Submitted Date{' '}
                </th>
                <th className="w-120 pl-13" colSpan="3">
                  Latest Update
                </th>
                <th className="w-120 pl-13" colSpan="7">
                  Current Status
                </th>
                <th className="w-150 pl-13" colSpan="7">
                  Submitted Location
                </th>
                <th className="pl-13" colSpan="8">
                  Submitted By
                </th>
                <th className="pl-13" colSpan="9">
                  Submitted At
                </th>
                <th className="w-250 pl-13 rounded-tr-lg" colSpan="10">
                  Consignment Details
                </th>
              </tr>
            </thead>
            {ReportDetails &&
              ReportDetails.map((table_Detail, index) => (
                <tbody key={index}>
                  <tr
                    className={` ${table_Detail.isSelected ? 'batch-active-row-bg ' : ''} text-left h-50 text-sm font-AvenirNextRegular bg-white  text-#000000 border border-gray-2`}
                  >
                    <td className="cursor-pointer underline pl-13" colSpan="1" onClick={() => searchItem(table_Detail.TradeId, false)}>
                      {table_Detail.TradeId}
                    </td>
                    <td className="w-130 pl-13 h-50" colSpan="1">
                      <span className=" font-bold">{'P'}&nbsp;</span>
                      {table_Detail.PrimaryDeviceIMEI}
                      <br />
                      {table_Detail.SecondaryDeviceIMEI ? (
                        <div>
                          <span className=" font-bold">{'S'}&nbsp;</span>
                          {table_Detail.SecondaryDeviceIMEI}{' '}
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="w-20 h-50 pl-13" colSpan="1">
                      <div>
                        <span>
                          {/* {table_Detail.Make}<br /> */}
                          {table_Detail.Model}
                        </span>
                      </div>
                    </td>
                    <td className="h-50 w-50 pl-13" colSpan="3">
                      {table_Detail.SubmittedDate && moment(table_Detail.SubmittedDate).format('DD/MM/YYYY')}
                    </td>
                    <td className="w-20 h-50 pl-13" colSpan="3">
                      {table_Detail.LastUpdated && moment(table_Detail.LastUpdated).format('DD/MM/YYYY')}
                    </td>
                    <td className="w-50 h-50 pl-13" colSpan="7">
                      {table_Detail.CurrentStatus}
                    </td>
                    <td className="h-50 w-160 pl-13" colSpan="7">
                      {table_Detail.RaisedLocation}
                    </td>
                    <td className="h-50 w-75 pl-13" colSpan="8">
                      {table_Detail.TeamMemberName}
                    </td>
                    <td className="h-50 w-120 pl-13" colSpan="9">
                      {table_Detail.SubmmitedAt}
                    </td>
                    <td className=" underline h-50 w-20 pl-13" colSpan="10">
                    {table_Detail.ConsignmentDetails ? <a rel="noopener noreferrer" href={table_Detail.RaisedLocation && table_Detail.RaisedLocation.toUpperCase() === 'CARE' ?  baseAuspostURL + table_Detail.ConsignmentDetails : baseURL + table_Detail.ConsignmentDetails} target='_blank'>{table_Detail.ConsignmentDetails}</a> : null}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        )
      ) : (
        <div className="mt-15 font-AvenirNextRegular text-dark-gray">No Records Found</div>
      )}
      {ReportDetails && ReportDetails.length > 0 && (
        <div className={` ${shoeReportingDetails ? 'hidden' : ''} flex justify-between mt-17`}>
          <div>
            Page {reportSelectedPage + 1} of {pageCount}
          </div>
          <Paginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={<a>...</a>}
            breakClassName={'break-me'}
            pageCount={pageCount}
            selectedPage={reportSelectedPage}
            onPageChange={(page) => setReportSelectedPage(page.selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div>
  )
}

export default ReportDetails
