import React, { useContext } from 'react'
import LabeledInputField from '../utils/custom-fields'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../Action'
import { store } from '../Store'
import { useHistory } from 'react-router-dom'

import HNLogo from '../assets/trade-logo.png'
import FreedomLogo from "../assets/freedom_logo.png"
import footerLogo from '../assets/img-asurion.png';


const forgotPassword = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const { register, errors, formState } = useForm({
        mode: 'onChange',
        defaultValues: {},
    })
    const { isValid } = formState
    const { Login, isForgotPswdClicked, isForgotPassFailure, forgotPasswordSuccessResponse, forgotPasswordError } = state
    const status = forgotPasswordSuccessResponse && forgotPasswordSuccessResponse.Status
    const { email, emailId } = Login
    const resources = state && state.resources
    const forgotError = forgotPasswordError && forgotPasswordError.Error
    const forgotErrorStatus = forgotPasswordError && forgotPasswordError.Status
    if (status && status === 'Success') {
        history.replace('/changepass')
    }
    let ErrorMessage = ''
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        forgotPassword();
    }

    const forgotPassword = () => {
        actions.forgotPasswordRequest({
            username: email || emailId
        })
    }
    return (
        <>
            <div className="bg-img1">
                <form onSubmit={handleSubmit} className="container">
                <div className="flex flex-row">
                    <img className="h-40" src={HNLogo} alt="trade-img" />
                    <img className="h-40 ml-30" src={FreedomLogo} alt="asurion-img" />
                </div>
                <div className="text-textBlack font-AvenirNextRegular font-semibold mt-90 text-3xl">Welcome to the Trade-In and Mobile Freedom Store Portal</div>
                <div className="text-lg font-AvenirNextRegular text-dark-gray mt-20">If you have your Trade-In Store Agent account, please login to start the journey</div>
                    <div className="flex-row inline-flex">
                        <div className="flex-col inline-block mt-70 mr-5">
                            <label className="text-sm leading-19 font-AvenirNextRegular text-black">
                                {resources.HARWAY_NORMAN_AGENT_ID_LABEL}
                            </label>
                            <br />
                            <LabeledInputField
                                name="username"
                                type="text"
                                labelClass="w-130 inline-block"
                                errorClass="text-center"
                                label="HARWAY_NORMAN_AGENT_ID_LABEL"
                                placeholder="HARWAY_NORMAN_EMAIL_PLACEHOLDER"
                                className="mt-3"
                                onChange={actions.setEmailId}
                                register={register}
                                errors={errors}
                                schema="verification"
                                defaultValue={Login && Login.email}
                                hideLabel
                            />
                            {isForgotPassFailure ? <div className="error-text pl-130 ">{forgotErrorStatus === 'failure' ? forgotError : forgotErrorStatus === 'account_lock' ? "Too many failed attempts. Your account has been locked, please try again in 10 minutes." : "" }</div> : null}
                            <img className="img-footer w-24" src={footerLogo} alt="asurion" />
                        </div>
                        <div className="flex-col inline-block mt-70 pt-4">
                            <button
                                disabled={!isValid}
                                type="submit"
                                style={{marginTop: '24px', padding: "14px 10px" }}
                                className={`text-white text-base rounded-6 ${isValid ? ' btn-search bg-HnSecondaryAmethyst' : 'btn-search bg-textPurple'} `}
                            >{resources.HARWAY_NORMAN_SUBMIT_BUTTON}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default forgotPassword