import React, { useContext, useEffect, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { store } from '../../Store'
import { useActions } from '../../Action'
import Button from '../common/button'
import hnLogo from '../../assets/HNMF_Logo.png'
import { TradeStatus } from '../../helper/AppConstants'

const ConfirmUpgardeDetails = [
  {
    key: 'New Device',
    id: 'UpgradeAsset',
  },
  {
    key: 'IMEI',
    id: 'IMEI',
  },
  {
    key: 'Upgrade ID',
    id: 'TradeQuoteNbr',
  },
  {
    key: 'Value',
    id: 'FinalPrice',
  },
]

const QrCodeContainer = ({ setShowQRCode }) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { tradeDetails, qrCodePreSignedUrl, qrCodeUploaded } = state
  const [qrCodeReference, setQrCodeReference] = useState(null)

  const qrCodeData = {
    imei: tradeDetails?.IMEI,
    value: tradeDetails?.FinalPrice,
    tradeId: tradeDetails?.TradeQuoteNbr,
  }

  const clearTradeDetails = () => {
    actions.clearTradeDetails()
  }

  const goBackToSearch = () => {
    setShowQRCode(false)
    clearTradeDetails()
  }

  const submitTradeDetails = () => {
    const data = {
      tradeId: tradeDetails?.TradeQuoteNbr,
      status: 'submitted',
      "partnerAgentStoreId": state && state.Login && state.Login.StoreId,
      "storeId": state && state.Login && state.Login.PartnerStoreId,
      isUpgrade: true
    }
    actions.submitTradeDetails(data)
  }

  useEffect(() => {
    const qrDoc = document.getElementById('HN-Upgrade-QR-Code')
    if (qrDoc) {
      setQrCodeReference(qrDoc)
    }
  }, [])

  useEffect(() => {
    if (qrCodeReference && tradeDetails?.TradeInStatus === TradeStatus.OPEN) {
      initializeUploadProcessForQrCode()
      submitTradeDetails()
    }
  }, [qrCodeReference])

  const initializeUploadProcessForQrCode = () => {
    const presignedData = {
      tradeId: tradeDetails?.TradeQuoteNbr,
      imageName: 'qrcode.png',
      method: 'put',
    }
    actions.getPreSignedUrl(presignedData)
  }

  useEffect(() => {
    if (qrCodePreSignedUrl) {
      uploadQrCodeImage()
    }
  }, [qrCodePreSignedUrl])

  const uploadQrCodeImage = async () => {
    const imgData = qrCodeReference.toDataURL('image/png')
    // convert imgData to blob
    const blobData = await (await fetch(imgData)).blob()
    const uploadData = {
      url: qrCodePreSignedUrl,
      file: blobData,
    }
    actions.uploadFileToS3(uploadData)
  }

  useEffect(() => {
    if (qrCodeUploaded) {
      sendEmailWithQrCode()
    }
  }, [qrCodeUploaded])

  const sendEmailWithQrCode = () => {
    const emailData = {
      SendWebEmailRequest: {
        Url: process.env.WEB_DOMAIN,
        TradeId: tradeDetails?.TradeQuoteNbr,
        TradeInValue: tradeDetails?.FinalPrice.toString(),
        ExpiryDate: tradeDetails?.OfferExpiryDate,
        DeviceCondition: 'GWO',
        DeviceType: 'Phone',
        ConditionArray: [],
      },
    }
    actions.sendEmailLabel(emailData)
  }

  return (
    <div className="w-full">
      <p className="font-bold text-3xl mt-50 text-center">Upgrade Confirmed</p>
      <div className="flex flex-col items-center mt-30 w-full">
        <div className="flex flex-col w-full max-w-md mx-auto">
          {ConfirmUpgardeDetails?.map((item, index) => {
            return (
              <div key={item?.key} className="flex flex-row py-6 border-border" style={{ borderBottomWidth: index + 1 !== ConfirmUpgardeDetails?.length && '1px' }}>
                <div className="w-32 text-sm text-textColor">{item?.key}</div>
                <div className="font-bold text-sm">{`${item?.key === 'Value' ? '$' : ''}${tradeDetails?.[item?.id] || 'NA'}`}</div>
              </div>
            )
          })}
        </div>
        {/* <img src={SampleQRCode} className="my-30 object-contain" style={{ height: '230px' }} /> */}
        <div className="py-30">
          <QRCodeCanvas
            id="HN-Upgrade-QR-Code"
            value={encodeURI(btoa(JSON.stringify(qrCodeData)))}
            renderAs="canvas"
            size={230}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ height: '230px' }}
            imageSettings={{
              src: hnLogo,
              x: undefined,
              y: undefined,
              height: 70,
              width: 70,
              excavate: true,
            }}
          />
        </div>
        <Button
          otherClass="active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm mb-30"
          label="Upgrade Complete"
          padding="px-30 py-11"
          onClick={goBackToSearch}
        />
      </div>
    </div>
  )
}

export default QrCodeContainer
