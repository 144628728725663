import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  label,
  onClick,
  disabled,
  otherClass,
  colorClass,
  textClass,
  padding,
  border,
  maxWidth,
  id,
  ignoreDisable
}) => {
  return (
    <button
      id={id}
      className={` ${padding} ${border} ${textClass} ${colorClass} ${otherClass} ${maxWidth} font-bold ${
        ignoreDisable
          ? ''
          : disabled
          ? 'text-btnDisableText bg-btnDisabled1 pointer-events-none'
          : `bg-primary`
      } ${!ignoreDisable && !disabled && 'text-white'} focus:outline-none`}
      onClick={onClick}
      disabled={disabled}>
      {label?.toUpperCase()}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  otherClass: PropTypes.string,
  colorClass: PropTypes.string,
  textClass: PropTypes.string,
  padding: PropTypes.string,
  border: PropTypes.string,
  maxWidth: PropTypes.string,
  ignoreDisable: PropTypes.bool
};

Button.defaultProps = {
  id: '',
  label: 'Submit Button',
  onClick: () => {},
  disabled: false,
  colorClass: '',
  otherClass: '',
  border: 'rounded',
  textClass: '',
  maxWidth: 'max-w-sm',
  ignoreDisable: false
};

export default Button;
