import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxChecked from  "../../assets/checkbox"
import CheckBox from  "../../assets/uncheckbox"

const Checkbox = ({ label, isChecked, disabled, onChange, className, type, labelClasses, fromLogistics, btnClass }) => {
  return (
    <div
      className={`cursor-pointer flex items-center ${className}`}
      onClick={() => (disabled ? {} : onChange(!isChecked))}>
              {isChecked ? <CheckBoxChecked className={`${btnClass}`} /> : <CheckBox className={`${btnClass}`} />}
        {/* <img src={isChecked ? CheckBoxChecked : CheckBox} className='object-contain h-20 w-auto'/>  */}
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  labelClasses: PropTypes.string,
  fromLogistics: PropTypes.bool
};

Checkbox.defaultProps = {
  isChecked: false,
  disabled: false,
  onChange: () => {},
  label: '',
  className: '',
  type: 'radio',
  labelClasses: '',
  fromLogistics: false
};

export default Checkbox;
