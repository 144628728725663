import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import CloseIcon from "../../assets/ic-close-gray.png"


const Modal = ({ open, setOpen, children, containerClasses, actions, showClose, isForm }) => {
;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-auto no-scrollbar">
          <div className="flex min-h-full items-end justify-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 sm:translate-y-4 translate-y-0 xs:scale-95 "
              enterTo="opacity-100 translate-y-0 xs:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 xs:scale-100"
              leaveTo="opacity-0 sm:translate-y-4 translate-y-0 xs:scale-95">
              <Dialog.Panel
                className={`${containerClasses} h-[90%] max-h-[90%] relative overscroll-y-auto z-100 transform  rounded-t-[24px] sm:rounded-b-[24px] bg-white  shadow-xl transition-all w-full sm:max-w-lg ${
                  isForm && 'md:max-w-2xl'
                }`}>
                {showClose && (
                  <div className="self-end flex flex-row justify-end">
                    <div
                      className="flex cursor-pointer"
                      onClick={() => {
                       setOpen(false);
                      }}>
                      <img src={CloseIcon} className='object-contain' />
                    </div>
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.element,
  containerClasses: PropTypes.string,
  actions: PropTypes.object,
  showClose: PropTypes.bool,
  isForm: PropTypes.bool,
  isChat: PropTypes.bool
};

Modal.defaultProps = {
  open: false,
  setOpen: () => {},
  containerClasses: '',
  showClose: true,
  isForm: false,
  isChat: false
};

export default Modal;
