import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import downIcon from '../../assets/ic-down-new.svg'
import upIcon from '../../assets/ic-up-new.svg'

const moment = require('moment')
const invokeOnce = true;
const ReportSummary = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { ReportingDetailsInputFields, Login, masterReportSummaryData, masterReportData, reportDetailsSuccess } = state
  const ReportDetails = masterReportSummaryData;
  let GetReportSummaryRequest = {
    PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
    TradeQuoteNbr: undefined,
    StartDate: undefined,
    EndDate: undefined,
    State: undefined,
    Status: undefined,
    StoreName: undefined,
    PageSize: 10,//sending just buz maindatory param
    PageNumber: 1,
  }
  useEffect(() => {
    actions.getReportingDetails({ GetReportSummaryRequest })
  }, [invokeOnce])

  let ConfirmedStatusData = ReportDetails && ReportDetails.filter(d => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'CONFIRMED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')
  let BatchedStatusData = ReportDetails && ReportDetails.filter(d => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'BATCHED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')

  let formattedData = function (filteredStatusData) {
    let UniqueStore = filteredStatusData &&
      [...new Set(filteredStatusData.map((item) => item.StoreName))]
    let FilteredData = [];
    UniqueStore && UniqueStore.map(d => {
      let StoreData = filteredStatusData && filteredStatusData.filter(c => c.StoreName === d)
      let DaysPendingInTransit = 0;
      let DaysPendingInAssessment = 0;
      let OverallAging = 0;
      StoreData && StoreData.map(data => {
        DaysPendingInTransit = DaysPendingInTransit + data.DaysPendingInTransit;
        DaysPendingInAssessment = DaysPendingInAssessment + data.DaysPendingInAssessment;
        OverallAging = OverallAging + data.OverallAging;
      })
      FilteredData.push({
        StoreName: d,
        JobInTransit: StoreData.length,
        DaysPendingInTransit: Math.round((DaysPendingInTransit / StoreData.length)),
        DaysPendingInAssessment: Math.round((DaysPendingInAssessment / StoreData.length)),
        OverallAging: Math.round((OverallAging / StoreData.length))
      })
    });
    return FilteredData;
  }

  let sum = function (key, array) {
    return array.reduce((a, b) => Number(a) + Number(b[key] || 0), 0);
  }

  let getMasterReportData = () => {
    const confirmFilteredData = formattedData(ConfirmedStatusData);
    const batchedFilteredData = formattedData(BatchedStatusData)
    let summaryData = [
      {
        OrderType: 'Unbatched Order',
        OrderDeatis: confirmFilteredData,
        JobInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? sum('JobInTransit', confirmFilteredData) : 0,
        DaysPendingInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', confirmFilteredData)) / confirmFilteredData.length) : 0,
        DaysPendingInAssessment: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', confirmFilteredData)) / confirmFilteredData.length) : 0,
        OverallAging: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('OverallAging', confirmFilteredData)) / confirmFilteredData.length) : 0,
        isSelectedMasterView: false
      },
      {
        OrderType: 'Ready For Pick Up Order',
        OrderDeatis: batchedFilteredData,
        JobInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? sum('JobInTransit', batchedFilteredData) : 0,
        DaysPendingInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', batchedFilteredData)) / batchedFilteredData.length) : 0,
        DaysPendingInAssessment: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', batchedFilteredData)) / batchedFilteredData.length) : 0,
        OverallAging: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('OverallAging', batchedFilteredData)) / batchedFilteredData.length) : 0,
        isSelectedMasterView: false
      },
      /* {
        OrderType: 'Completed Orders',
        OrderDeatis: confirmFilteredData,
        JobInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? (sum('JobInTransit', confirmFilteredData) +  sum('JobInTransit', batchedFilteredData)) : 0,
        DaysPendingInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('DaysPendingInTransit', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('DaysPendingInTransit', batchedFilteredData)) / batchedFilteredData.length)): 0,
        DaysPendingInAssessment: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('DaysPendingInAssessment', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('DaysPendingInAssessment', batchedFilteredData)) / batchedFilteredData.length)) : 0,
        OverallAging: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('OverallAging', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('OverallAging', batchedFilteredData)) / batchedFilteredData.length)) : 0,
        isSelectedMasterView: false
      }, */
    ];
    actions.setMasterViewData(summaryData);
  }

  let setSelectedMasterView = (OrderType) => {
    masterReportData && masterReportData.map((s, i) => {
      if (s.OrderType === OrderType) {
        s.isSelectedMasterView = !s.isSelectedMasterView;
      }
    })
    actions.setMasterViewData(masterReportData)
  }

  if (reportDetailsSuccess && ReportDetails && ReportDetails.length > 0 && masterReportData.length == 0) {
    getMasterReportData();
  }
  return (
    <div>
      {ReportDetails && ReportDetails.length > 0 &&
        masterReportData && masterReportData.length > 0 && (
          <div>
            <table className="mt-5 text-sm font-AvenirNextRegular font-bold w-full">
              <thead className=" bg-textPurple text-white h-60 text-left w-full">
                <tr className="w-full h-60">
                  <th className="w-250 rounded-tl-lg"></th>
                  <th className="w-250 h-34 font-AvenirNextRegular text-14 leading-16 text-center uppercase">
                    Jobs In Transit
                  </th>
                  <th className="w-250 h-34 font-AvenirNextRegular text-14 text-center leading-16 uppercase">
                    Days Pending in Transit
                  </th>
                  <th className="h-34 font-AvenirNextRegular text-14 text-center leading-16 uppercase">
                    Days Pending Assessment
                  </th>
                  <th className="h-34 font-AvenirNextRegular text-14  text-center leading-16 uppercase">
                    Overall Aging
                  </th>
                  <th className="h-34 font-AvenirNextRegular text-14 text-center leading-16 rounded-tr-lg">
                  </th>
                </tr>
              </thead>
              {masterReportData &&
                masterReportData.map((s, index) => (
                  <tbody key={index} className="border border-gray-100">
                    <tr className={`text-sm text-dark-gray h-50 ${s.isSelectedMasterView ? 'bg-textPurple bg-opacity-25' : 'text-white'} border-gray-1`}>
                      <td className={`w-250 inline-flex ${s.isSelectedMasterView ? 'text-textPurple font-AvenirNextMedium' : 'text-dark-gray font-AvenirNextRegular'} font-bold text-14 leading-16 m-15`}>
                        <img className="inline-flex" onClick={() => setSelectedMasterView(s.OrderType)} src={s.isSelectedMasterView ? upIcon :  downIcon} />
                        <span className="ml-30">{s.OrderType}</span>
                      </td>
                      <td className={`w-250 h-10 text-dark-gray text-sm font-bold text-center ${s.isSelectedMasterView ? 'text-dark-purple font-AvenirNextMedium' : 'text-dark-gray'}`}>{s.JobInTransit}</td>
                      <td className={`w-250 h-10 text-dark-gray text-sm font-bold text-center ${s.isSelectedMasterView ? 'text-dark-purple font-AvenirNextMedium' : 'text-dark-gray'}`}>{s.DaysPendingInTransit}</td>
                      <td className={`w-250 h-10 text-dark-gray text-sm font-bold text-center ${s.isSelectedMasterView ? 'text-dark-purple font-AvenirNextMedium' : 'text-dark-gray'}`}>{s.DaysPendingInAssessment}</td>
                      <td className={`w-250 h-10 text-dark-gray text-sm font-bold text-center ${s.isSelectedMasterView ? 'text-dark-purple font-AvenirNextMedium' : 'text-dark-gray'}`}>{s.OverallAging}</td>
                      <td className=""></td>
                    </tr>
                    {s && s.isSelectedMasterView &&
                      s.OrderDeatis.map((d, idx) => (
                        <tr key={idx} className="text-sm text-dark-gray bg-white h-50 border border-gray-1">
                          <td className="w-280 text-dark-gray font-AvenirNextRegular text-14 leading-16 text-right font-bold">{d.StoreName}</td>
                          <td className="w-250 h-10 text-dark-gray text-sm  text-center">{d.JobInTransit}</td>
                          <td className="w-250 h-10 text-dark-gray text-sm  text-center">{d.DaysPendingInTransit}</td>
                          <td className="w-250 h-10 text-dark-gray text-sm  text-center">{d.DaysPendingInAssessment}</td>
                          <td className="w-250 h-10 text-dark-gray text-sm  text-center">{d.OverallAging}</td>
                          <td className="h-30"></td>
                        </tr>
                      ))}
                  </tbody>
                ))}
            </table>
          </div>
      )
        //  : (
        //   <div className="mt-15">No Records Found</div>
        // )
    }
    </div>
  )
}

export default ReportSummary
