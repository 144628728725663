import React from 'react';
import PropTypes from 'prop-types';
import FMIPHeaderImage from "../../assets/fmip-modal.png"
import Button from '../common/button';
import Modal from '../common/modal';
import { Config } from '../../config';
// import sanitize from 'sanitize-html';

const FMIPMODAL = ({ open, setOpen, handleFmipModal }) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      showClose={false}
      containerClasses={'p-0 border-HnSecondaryAmethyst rounded-2xl'}>
      <div className="rounded-t-20" >
        <div  style={{ borderTopRightRadius: '14px', borderTopLeftRadius: '14px' ,borderColor: '#6B4EA0' }} className="bg-HnSecondaryAmethyst flex flex-col p-30 border-HnSecondaryAmethyst border-[.1px] text-white">
          <img src={FMIPHeaderImage} className="self-center mx-auto object-contain" style={{height: "120px"}} />
          <div>
            <p className='text-sm my-20'>
            Oh no! The device being upgraded still has device locks enabled. Ensure the customer removes the locks to proceed. Below are more instructions on how to remove these locks.
            </p>
            <p className="text-sm font-bold mb-10">{'Device unlock instructions for Apple'}</p>
            <ol className="list-decimal px-4">
              <li className='text-sm'>
                Log in to your iCloud account (
                <span
                  onClick={() => {
                    window.open(
                      Config?.externalUrls?.icloudUrl,
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="text-lightBlue font-semibold cursor-pointer">
                  www.icloud.com
                </span>
                ).
              </li>
              <li className='text-sm'>
                Click <span className="font-bold">{'Find My iPhone > All Devices.'}</span>
              </li>
              <li className='text-sm'>
                Identify the device (Active devices appear with a green dot, while inactive devices
                have a grey dot).
              </li>
              <li className='text-sm'>Click on the name of the device.</li>
              <li className='text-sm'>
                Click <span className="font-bold">Remove from Account.</span>
              </li>
            </ol>
          </div>
          <div>
            <p
              className="text-sm font-bold mb-10 mt-20"
            >Device unlock instructions for Android</p>
            <ol className="list-decimal px-4">
              <li className='text-sm'>
                Go to <span className="font-bold">{'Settings.'}</span>
              </li>
              <li className='text-sm'>
                Tap the <span className="font-bold">{"'Biometrics and security'"}</span> menu.
              </li>
              <li className='text-sm'>
                Go to <span className="font-bold">{"'Find My Mobile.'"}</span>
              </li>
              <li className='text-sm'>Tap Switch Off.</li>
            </ol>
          </div>
        </div>
        <div className="py-14 items-center justify-center flex">
             <Button
                otherClass={'active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5'}
                label={'GO BACK'}
                padding="px-14 py-11"
                onClick={() => {
                    setOpen(false)
                }}
              />
             <Button
                otherClass={'active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5'}
                label={'CONTINUE'}
                padding="px-14 py-11"
                onClick={() => {
                  handleFmipModal();
                }}
              />
        </div>
      </div>
    </Modal>
  );
};

FMIPMODAL.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleFmipModal: PropTypes.func
};

export default FMIPMODAL;
