import React from 'react';
import PropTypes from 'prop-types';

const tableData = [
  {
    header: 'Device Price (RRP)',
    data: ['$250 - $799', '$800 - $1499', '$1500+']
  },
  {
    header: 'Subscription Fee',
    data: ['$129', '$249', '$299']
  },
  {
    header: 'Mobile Refresh Fee',
    data: ['$49', '$49', '$99']
  },
  {
    header: 'Swap Fee',
    data: ['$79', '$149', '$199']
  },
  {
    header: 'Upgrade Fee*',
    data: ['$179', '$249', '$299']
  }
];
const MFCostTable = ({ borderClasses, headerClasses, padding }) => {
  return (
    <table id={'mfCostTable'} className={`border-collapse w-full ${borderClasses}`}
    
    >
      {tableData.map((row, index) => (
        <tr key={index} >
          <th style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}} className={`${borderClasses} ${headerClasses} ${padding} text-sm`}>{row.header}</th>
          {row.data.map((col, i) => (
            <td className={`${borderClasses} ${padding} text-sm`} key={i}>
              {col}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

MFCostTable.propTypes = {
  borderClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  padding: PropTypes.string
};

export default MFCostTable;
