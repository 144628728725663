import React, { useContext, useEffect, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { store } from '../../Store'
import { useActions } from '../../Action'
import Button from '../common/button'
import hnLogo from '../../assets/HNMF_Logo.png'
import { TradeStatus } from '../../helper/AppConstants'
import ActionTypes from "../../ActionTypes"
import API from '../../API'
import FileSaver from 'file-saver';

const ConfirmUpgardeDetails = [
  {
    key: 'Device to Return',
    id: 'TradeDevice',
  },
  {
    key: 'IMEI or Serial Number',
    id: 'IMEI',
  },
  {
    key: 'Trade ID',
    id: 'TradeQuoteNbr',
  },
  {
    key: 'Trade In Value',
    id: 'FinalPrice',
  },
]

const QrCodeContainer = ({ setShowQRCode }) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { tradeDetails, qrCodePreSignedUrl, qrCodeUploaded, tradeSubmitted } = state
  const [qrCodeReference, setQrCodeReference] = useState(null)

  const qrCodeData = {
    imei: tradeDetails?.IMEI,
    value: tradeDetails?.FinalPrice,
    tradeId: tradeDetails?.TradeQuoteNbr,
  }

  const clearTradeDetails = () => {
    actions.clearTradeDetails()
  }

  const goBackToSearch = () => {
    setShowQRCode(false)
    clearTradeDetails()
  }

  useEffect(() => {
    const qrDoc = document.getElementById('HN-Trade-QR-Code')
    if (qrDoc) {
      setQrCodeReference(qrDoc)
    }
  }, [])

  useEffect(() => {
    if (qrCodeReference && tradeDetails?.TradeInStatus === TradeStatus.OPEN) {
      initializeUploadProcessForQrCode()
    }
  }, [qrCodeReference])

  const initializeUploadProcessForQrCode = () => {
    const presignedData = {
      tradeId: tradeDetails?.TradeQuoteNbr,
      imageName: 'qrcode.png',
      method: 'put',
    }
    actions.getPreSignedUrl(presignedData)
  }

  useEffect(() => {
    if (qrCodePreSignedUrl) {
      uploadQrCodeImage()
    }
  }, [qrCodePreSignedUrl])

  const uploadQrCodeImage = async () => {
    const imgData = qrCodeReference.toDataURL('image/png')
    // convert imgData to blob
    const blobData = await (await fetch(imgData)).blob()
    const uploadData = {
      url: qrCodePreSignedUrl,
      file: blobData,
    }
    actions.uploadFileToS3(uploadData)
  }

  useEffect(() => {
    const emailData = {
      SendWebEmailRequest: {
        Url: process.env.WEB_DOMAIN,
        TradeId: tradeDetails?.TradeQuoteNbr,
        TradeInValue: tradeDetails?.FinalPrice.toString(),
        ExpiryDate: tradeDetails?.OfferExpiryDate,
        DeviceCondition: 'GWO',
        DeviceType: 'Phone',
        ConditionArray: [],
      },
    }

    const data = {
      "tradeId": tradeDetails?.TradeQuoteNbr,
      "status": "CONFIRMED",
      "partnerAgentStoreId": state && state.Login && state.Login.StoreId,
      "storeId": state && state.Login && state.Login.PartnerStoreId,
      "isUpgrade": false
  
  }

    if (qrCodeUploaded) {
      actions.submitTradeDetails_V2({
        submitTradeRequest: data,
        qrEmailRequest: emailData
      })

    }
  }, [qrCodeUploaded])

  const handleGenerateLabel = () =>{
    actions.setLoader()
    API[ActionTypes.GET_RETURN_LABEL](tradeDetails?.TradeQuoteNbr)
    .then((data) => {
        actions.unsetLoader()    
        triggerDownload(data?.data?.ParcelLabelResponse) 
    })
    .catch((error) => { 
        actions.unsetLoader() 
    });
  }

  const  Base64toBlob =(b64Data, contentType = "application/pdf", sliceSize = 512)=> {
    const byteCharacters = atob(b64Data?.replace(/-/g, "+").replace(/_/g, "/"));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const triggerDownload = (base64String) =>{
    
    const fileName = `${tradeDetails?.TradeQuoteNbr}_Elabel.pdf`;
    
    FileSaver.saveAs(Base64toBlob(base64String), fileName)
      // if (window.navigator && window.navigator.msSaveBlob) {
      //   const blob = Base64toBlob(base64String);
      //   window.navigator.msSaveBlob(blob, fileName);
      // } else {
      //   const linkSource = `data:application/pdf;base64,${base64String}`;
      //   const downloadLink = document.createElement("a");
      //   downloadLink.href = linkSource;
      //   downloadLink.download = `${tradeDetails?.TradeQuoteNbr}_Elabel.pdf`;
      //   downloadLink.click();
      // } 
  }
  

  return (
    <div className="w-full">
      <p className="font-bold text-3xl mt-50 text-center">Trade-in Confirmed</p>
      <div className="flex flex-col items-center mt-30 w-full">
        <div className="flex flex-col w-full max-w-md mx-auto">
          {ConfirmUpgardeDetails?.map((item, index) => {
            return (
              <div key={item?.key} className="flex flex-row py-6 border-border" style={{ borderBottomWidth: index + 1 !== ConfirmUpgardeDetails?.length && '1px' }}>
                <div className="w-200 text-lg text-textColor">{item?.key}</div>
                <div className="w-240 font-bold text-lg">{`${item?.key === 'Trade In Value' ? '$' : ''}${tradeDetails?.[item?.id] || 'NA'}`}</div>
              </div>
            )
          })}
        </div>
        {/* <img src={SampleQRCode} className="my-30 object-contain" style={{ height: '230px' }} /> */}
        <div className="py-30">
          <QRCodeCanvas
            id="HN-Trade-QR-Code"
            value={encodeURI(btoa(JSON.stringify(qrCodeData)))}
            renderas="canvas"
            size={230}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ height: '230px' }}
          />
        </div>
        <Button
          otherClass="active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm mb-30 w-260"
          label="TRADE IN COMPLETE"
          padding="px-30 py-11"
          onClick={goBackToSearch}
        />
        <p className='pt-50 max-w-2xl px-10 text-textColor text-center'>
        Your customer will receive their return label via email, they can present the return label at Australia Post on a device without printing. Packaging is provided free of charge. If your customer would like your assistance to print the return label you can download it below.
        </p>
        <Button
          otherClass="active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm mb-30 mt-20 w-260"
          label="DOWNLOAD RETURN LABEL"
          padding="px-30 py-11"
          onClick={handleGenerateLabel}
          disabled={!tradeDetails?.tradeSubmitted}
        />
      </div>
    </div>
  )
}

export default QrCodeContainer;
