import React, { useContext } from 'react'
import { MemoryRouter as Router, Switch, Route, Link } from 'react-router-dom'
import OrderDetails from './logistics-orderdetails'
import BatchDetails from './logistics-batchdetails'
import { store } from '../../Store'
import { useActions } from '../../Action'
import { DeviceTypes } from '../../helper/AppConstants'

const Logistics = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { LogisticsInputField } = state
  const deviceType = LogisticsInputField && LogisticsInputField.DeviceType;

  const getSelectedDeviceType = (type) => {
    return {
      'macbook': DeviceTypes.MACBOOK,
      'surface': DeviceTypes.SURFACE,
      'phones/wearables/tablets': DeviceTypes.PHONE_TABLET,
      'lvd laptop': DeviceTypes.LVD_LAPTOP,
      'lvd phone/lvd wearables/lvd tablet': DeviceTypes.LVD_PWT,
      'select device type': 'Select Device Type'
    }[type]
  }

  const batchDetailsTab = () => {
    actions.setOrderDetailsTab(false)
  }

  const orderDetailsTab = () => {
    actions.setOrderDetailsTab(true)
    let GetDeviceListRequest = {
      PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
      PartnerParentCode: state && state.Login && state.Login.PartnerParentStoreCode,
      PageSize: 10,
      PageNumber: 1,
      DeviceType: getSelectedDeviceType(deviceType?.toLowerCase())
    }
    actions.setSelectedPage(0)
    actions.clearDeviceList()
    actions.getLogistics({ GetDeviceListRequest })
  }

  let LogisticsOrderDetailsTab = state && state.LogisticsOrderDetailsTab
 
  return (
    <Router>
      <div className="min-h-screen">
      <div className="xl:px-100 px-50 mb-50 font-AvenirNextRegular">
        <div className="flex items-center pt-30">
          <h2 className="text-3xl font-bold leading-37 text-dark-gray font-AvenirNextRegular mr-50">Logistics</h2>
          <div className={` cursor-pointer ${LogisticsOrderDetailsTab ? 'text-white bg-lightBlue' : 'text-lightBlue bg-white border border-lightBlue'} rounded-md  py-12 px-10 text-base text-center font-bold font-AvenirNextRegular`}>
            <Link
              to="/orderdetails"
              onClick={() => {
                orderDetailsTab()
              }}
            >
              ORDER DETAILS
            </Link>
          </div>
          <div className={` cursor-pointer ml-10 ${!LogisticsOrderDetailsTab ? 'text-white bg-lightBlue' : 'text-lightBlue bg-white border border-lightBlue'} rounded-md  py-12 px-10 text-base text-center font-bold font-AvenirNextRegular`}>
            <Link
              to="/batchdetails"
              onClick={() => {
                batchDetailsTab()
              }}
            >
              BATCH DETAILS
            </Link>
          </div>
        </div>
        <Switch>
          <Route path="/orderdetails">
            <OrderDetails />
          </Route>
          <Route path="/batchdetails">
            <BatchDetails />
          </Route>
          <Route path="/">
            <OrderDetails />
          </Route>
        </Switch>
      </div>
      </div>
    </Router>
  )
}
export default Logistics
