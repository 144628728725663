import React from 'react'
import { useContext, useEffect, useState } from 'react';
import { useActions } from '../../Action';
import { store } from '../../Store';
import LabeledInputField, { RenderSelectField, FloatLabelTextAreaField } from '../../utils/custom-fields'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development';
import arrowUp from '../../assets/ic-chevron_up.png';
import arrowDown from '../../assets/ic-chevron_down.png';
import UpArrowPng from  "../../assets/ic-arrow-up-blue.png"
import DownArrowPng from  "../../assets/ic-down-arrow.png"
import { FreedomFAQs } from "./freedomFaq.js"


const INVOKE_ONCE = true;

const ContactForm = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const [selectedQuestion, setQuestion] = useState("")
    const { ContactUs, resources } = state;
    const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })
    const { contactUsMasterData, EnquiryQuestion, TradeId, DeviceMake, DeviceModel, FirstName, LastName, ContactEmail, ContactNumber, State, ExtraDetails, sendEmailSuccess, resetContactUsForm, FAQData, showContactUsMessage } = ContactUs;
    useEffect(() => {
        actions.getFaqList()
        actions.getContactUsMasterData()
      }, [INVOKE_ONCE])

    useEffect(() => {
        return () => {
            actions.setContactUsQuestion(undefined);
            actions.setContactUsTradeId(undefined);
            actions.setContactUsDeviceMake(undefined);
            actions.setContactUsDeviceModel(undefined);
            actions.setContactUsFirstName(undefined);
            actions.setContactUsLastName(undefined);
            actions.setContactUsContactEmail(undefined);
            actions.setContactUsContactNumber(undefined);
            actions.setContactUsState(undefined);
            actions.setContactUsExtraDetails(undefined);
        }
    }, []);

    const resetFields = () => {
        actions.setContactUsQuestion(undefined);
        actions.setContactUsTradeId(undefined);
        actions.setContactUsDeviceMake(undefined);
        actions.setContactUsDeviceModel(undefined);
        actions.setContactUsFirstName(undefined);
        actions.setContactUsLastName(undefined);
        actions.setContactUsContactEmail(undefined);
        actions.setContactUsContactNumber(undefined);
        actions.setContactUsState(undefined);
        actions.setContactUsExtraDetails(undefined);
        actions.resetContactUsForm(false);
        actions.showContactUsMessage(true);
        setValue("EnquiryQuestion", "")
        setValue("tradeID", "")
        setValue("DeviceMake", "")
        setValue("DeviceModel", "")
        setValue("FirstName", "")
        setValue("LastName", "")
        setValue("ContactEmail", "")
        setValue("ContactNumber", "")
        setValue("State", "")
        setValue("ExtraDetails", "")
    }

    let enableButton = (errors && errors.length > 0) || (!EnquiryQuestion || !FirstName || !LastName || !ContactEmail || !State || !ExtraDetails) ? false : true;
    let defaultLabel = [{ key: "Select Options Below", value: "Select Options Below" }];
    let questions = contactUsMasterData && contactUsMasterData.EnquiryQuestions.map((item) => ({ key: item, value: item })) || defaultLabel;
    let deviceMakes = contactUsMasterData && contactUsMasterData.DeviceMakes.map((item) => ({ key: item, value: item })) || defaultLabel;
    let states = contactUsMasterData && contactUsMasterData.States.map((item) => ({ key: item.ReferenceName, value: item.ReferenceName })) || defaultLabel;
    let defaultQuestions = [...defaultLabel, ...questions];
    let defaultMake = [...defaultLabel, ...deviceMakes];
    let defaultState = [...defaultLabel, ...states];
    let supportEmail = State && contactUsMasterData && contactUsMasterData.States.reduce((agr, i) => State === i.ReferenceName ? [...agr, i.ReferenceParameterList1] : agr, [])[0];
    let submit = () => {
        let SendEnquiryEmailRequest = {
            "EnquiryQuestion": EnquiryQuestion,
            "TradeId": TradeId,
            "DeviceMake": DeviceMake && DeviceMake === 'Select Options Below' ? undefined : DeviceMake,
            "DeviceModel": DeviceModel,
            "FirstName": FirstName,
            "LastName": LastName,
            "ContactEmail": ContactEmail,
            "ContactNumber": ContactNumber,
            "State": State,
            "ExtraDetails": ExtraDetails,
            "SupportEmail": supportEmail
        };
        actions.resetContactUsForm(true);
        actions.sendEnquiryEmail({ SendEnquiryEmailRequest });
    }
    if (sendEmailSuccess && resetContactUsForm) {
        resetFields()
    }
    const showAns = (value) => {
        FAQData.map(f => {
            //  f.showAnswer = false;
            if (f.Question === value) {
                f.showAnswer = !f.showAnswer;
            }
        })
        actions.setFAQData(FAQData)
    }

    const showAnswer = (question)=>{
        let tempQuestion = question;
        if( selectedQuestion === tempQuestion)
        {
          setQuestion('')
        }
        else{
          setQuestion(tempQuestion)
        }
    }
    return (
        <div className="flex flex-row mx-auto justify-evenly">
            <div style={{maxWidth: '620px'}} className="flex flex-col">
                <p className="text-xl font-AvenirNextRegular text-dark-gray mt-31  font-semibold mb-4">Frequently Asked Questions</p>
                <p  className='text-textPurple text-base font-semibold my-2 pl-4'>Trade-in</p>
                <div  style={{width: '620px'}}  className="">
                    {FAQData &&
                        FAQData.map((value, key) => (
                            <div
                                key={key}
                                style={{
                                    borderTopWidth: '1px',
                                    // borderBottomWidth: value?.showAnswer && '1px',
                                    borderColor: value?.showAnswer ? '#00AEBE': '#C0CACF',
                                    backgroundColor: value?.showAnswer && 'rgba(0, 174, 190, 0.05)'
                                }}
                                className={` text-left py-12 max-w-xl w-full`}>
                                <div className=" flex flex-row justify-between p-0 cursor-pointer pl-5 pr-5 pb-2 pt-2" onClick={() => showAns(value?.Question)}>
                                    <p className={`${value?.showAnswer ? 'font-bold text-lightBlue' : 'text-textColor'} text-left font-AvenirNextRegular text-18 leading-21 sm:text-16 sm:leading-19 py-25 inline-block`}>
                                        {value?.Question}
                                    </p>
                                    <img
                                        className="cursor-pointer inline-block float-right object-contain"
                                        src={value?.showAnswer ? UpArrowPng : DownArrowPng}
                                    />
                                </div>
                                {value.showAnswer && <div
                                    // onClick={(e) => openLink(e)}
                                    dangerouslySetInnerHTML={{ __html: key == 6 ? value.Answer.replace(/url/g,process.env.WEB_DOMAIN) : value?.Answer }}
                                    className="font-AvenirNextRegular text-left text-18 leading-24 sm:text-16 sm:leading-20 pb-5 pt-5 text-dark-gray pl-5 pr-5">
                                </div>}
                            </div>
                        ))}
                </div>
                <p  className='text-textPurple text-base font-semibold my-2 pl-4'>Mobile Freedom</p>
                <div className="">
                    {FreedomFAQs &&
                        FreedomFAQs.map((value, key) => (
                            <div
                                key={key}
                                style={{
                                    borderTopWidth: '1px',
                                    borderColor: value?.question === selectedQuestion  ? '#00AEBE': '#C0CACF',
                                    backgroundColor: value?.question === selectedQuestion  && 'rgba(0, 174, 190, 0.05)'
                                }}
                                className={` text-left py-12 max-w-xl w-full`}>
                                <div className="flex flex-row justify-between  p-0 cursor-pointer pl-5 pr-5 pb-2 pt-2" onClick={() => showAnswer(value?.question)}>
                                    <p className={`${value?.question === selectedQuestion  ? 'font-bold text-lightBlue' : 'text-textColor'} text-left font-AvenirNextRegular text-18 leading-21 sm:text-16 sm:leading-19 py-25 inline-block`}>
                                        {value?.question}
                                    </p>
                                    <img
                                        // style={{height: '4px'}}
                                        className="cursor-pointer inline-block float-right object-contain"
                                        src={value?.question === selectedQuestion  ? UpArrowPng : DownArrowPng}
                                    />
                                </div>
                                {value?.question === selectedQuestion  && <div
                                    // onClick={(e) => openLink(e)}
                                    // dangerouslySetInnerHTML={{ __html: value?.answer }}
                                    className="font-AvenirNextRegular text-left text-18 leading-24 sm:text-16 sm:leading-20 pb-5 pt-5 text-dark-gray pl-5 pr-5">
                                        {value?.answer}
                                </div>}
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex-col ">
                <div className="flex w-full items-center">
                    <h2 className="text-xl font-AvenirNextRegular text-dark-gray mt-31 font-semibold mb-4">Contact Asurion Form</h2>
                </div>
                <div className='flex flex-row pb-10 items-center pt-8'>
                    <div className="flex align-items-center flex-col">
                        <RenderSelectField
                            style={{
                                marginTop: '0px',
                                width: '290px'
                            }}
                            name="EnquiryQuestion"
                            label={resources.CONTACTUS_LBL_ENQUIRY_QUESTION}
                            className="contact-us-drop-input search-font mb-1"
                            register={register}
                            onChange={(e) => actions.setContactUsQuestion(e)}
                            options={defaultQuestions}
                            optionsKey="key"
                            optionsValue="value"
                            errors={errors}
                            schema="reporting"
                            value={ContactUs ? ContactUs.EnquiryQuestion : ''}
                            removeDefaultOption={true}
                            Mandatory />
                    </div>
                    <div className="flex align-items-center flex-col">
                        <LabeledInputField
                         style={{
                            width: '290px'
                        }}
                            className="contact-us-input search-font mb-1"
                            type="text"
                            name="tradeID"
                            label={resources.HN_CUSTOMER_TRADE_ID}
                            schema="reporting"
                            placeholder="Trade ID"
                            onChange={actions.setContactUsTradeId}
                            register={register}
                            errors={errors}
                        />
                    </div>
                </div>
                <div className='flex flex-row pb-10 items-center'>
                <div className="flex align-items-center flex-col">
                    <RenderSelectField
                        style={{
                            marginTop: '0px',
                            width: '290px'
                        }}
                        name="DeviceMake"
                        label={resources.CONTACTUS_LBL_DEVICE_MAKE}
                        className="contact-us-drop-input search-font mb-1"
                        register={register}
                        onChange={(e) => actions.setContactUsDeviceMake(e)}
                        options={defaultMake}
                        optionsKey="key"
                        optionsValue="value"
                        errors={errors}
                        schema="reporting"
                        value={ContactUs ? ContactUs.DeviceMake : ''}
                        removeDefaultOption={true}
                    />
                </div>
                <div className="flex align-items-center flex-col">
                    <LabeledInputField
                      style={{
                        width: '290px'
                         }}
                        className="contact-us-input search-font mb-1"
                        type="text"
                        name="DeviceModel"
                        label={resources.CONTACTUS_LBL_DEVICE_MODEL}
                        schema="reporting"
                        placeholder="Device Model"
                        onChange={actions.setContactUsDeviceModel}
                        register={register}
                        errors={errors}
                    />
                </div>
                </div> 
                    
                <div className='flex flex-row pb-10'>
                    <div className="flex align-items-center flex-row">
                        <div className="flex flex-col">
                            <LabeledInputField
                              style={{
                                width: '134px'
                            }}
                                className="name-input search-font mb-1"
                                type="text"
                                name="FirstName"
                                label={resources.CONTACTUS_LBL_FIRST_NAME}
                                schema="reporting"
                                placeholder={resources.CONTACTUS_LBL_FIRST_NAME}
                                onChange={actions.setContactUsFirstName}
                                register={register}
                                errors={errors}
                                Mandatory
                            />
                        </div>
                        <div className="flex flex-col">
                            <LabeledInputField
                              style={{
                                width: '134px'
                            }}
                                labelClass="ml-1"
                                className="name-input search-font mb-1 ml-2"
                                type="text"
                                name="LastName"
                                label={resources.CONTACTUS_LBL_LAST_NAME}
                                schema="reporting"
                                placeholder={resources.CONTACTUS_LBL_LAST_NAME}
                                onChange={actions.setContactUsLastName}
                                register={register}
                                errors={errors}
                                Mandatory
                            />
                        </div>
                    </div>
                    <div className="flex align-items-center flex-col">
                        <LabeledInputField
                          style={{
                            width: '290px'
                        }}
                            className="contact-us-input search-font mb-1"
                            type="text"
                            name="ContactEmail"
                            label={resources.CONTACTUS_LBL_CONTACT_EMAIL}
                            schema="reporting"
                            placeholder={resources.CONTACTUS_LBL_CONTACT_EMAIL}
                            onChange={actions.setContactUsContactEmail}
                            register={register}
                            errors={errors}
                            Mandatory
                        />
                    </div>
                </div>
                <div className='flex flex-row pb-10'>
                    <div className="flex align-items-center flex-col">
                        <LabeledInputField
                          style={{
                            width: '290px'
                        }}
                            className="contact-us-input search-font mb-1"
                            type="text"
                            name="ContactNumber"
                            label={resources.CONTACTUS_LBL_CONTACT_NUMBER}
                            schema="reporting"
                            placeholder={resources.CONTACTUS_LBL_CONTACT_NUMBER}
                            onChange={actions.setContactUsContactNumber}
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div className="flex align-items-center flex-col">
                        <RenderSelectField
                            style={{
                                marginTop: '0px',
                                width: '290px'
                            }}
                            name="State"
                            label={resources.CONTACTUS_LBL_STATE}
                            className="contact-us-drop-input search-font mb-1"
                            register={register}
                            onChange={(e) => actions.setContactUsState(e)}
                            options={defaultState}
                            optionsKey="key"
                            optionsValue="value"
                            errors={errors}
                            schema="reporting"
                            value={ContactUs ? ContactUs.State : ''}
                            removeDefaultOption={true}
                            Mandatory />
                    </div>
                </div>
                <div className="flex align-items-center flex-col">
                    <label className="text-sm leading-19 font-AvenirNextRegular text-dark-gray">
                        Please share more details about the nature of your enquiry <span className="text-red-600 ml-1">*</span>
                    </label>
                    <FloatLabelTextAreaField
                        style={{
                            width: '595px'
                        }}
                        className="contact-us-extra-input search-font mb-1 mt-1"
                        type="text"
                        name="ExtraDetails"
                        maxlength={500}
                        rows="10"
                        //label={resources.CONTACTUS_LBL_EXTRA_DETAILS}
                        schema="reporting"
                        //placeholder={resources.CONTACTUS_LBL_EXTRA_DETAILS}
                        onChange={actions.setContactUsExtraDetails}
                        register={register}
                        errors={errors}
                        Mandatory
                        hideLabel
                    />
                      <span style={{
                        color: '#CB1829',
                        textAlign: 'right',
                        marginRight: '16px'
                      }} className="text-sm mt-1">{500 - (ExtraDetails?.length ?? 0)}/500</span>
                </div>
                <div className="flex align-items-center justify-center flex-row mt-4">
                        <button 
                            style={{padding: "14px 72px" }} 
                            type="submit" className={` font-bold focus:outline:none text-white text-base rounded-6 ${enableButton ? 'bg-darkBlue' : 'bg-lightBlue'} `}
                            onClick={() => submit()}
                            disabled={!enableButton}
                        >
                            SUBMIT
                       </button>
                    {
                        showContactUsMessage && <p className="text-green-600 font-bold ml-5 mt-3"> Your Request has been submitted successfully</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactForm