import React, { useContext, useState, useEffect } from 'react'
import { store } from '../../Store'
import FmipModal from './fmipModal'
import UpgradeConfirmationModal from './upgradeConfirmationModal'
import CustomerInfoModal from './customerInfoModal'
import ConfirmUpgrade from './confirmUpgrade'
import UpgradeDetailsAndChecklist from './upgradeDetailsAndChecklist'
import QrCodeContainer from './qrCodeContainer'
import { DeviceMakes } from '../../helper/AppConstants'
import { useActions } from '../../Action'


function UpgradeDetails() {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const [showFmipModal, setShowFmipModal] = useState(false)
  const [showUpgradeConfirmationModal, setShowUpgradeConfirmationModal] = useState(false)
  const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const { tradeDetails } = state

  const handleFmipModal = () => {
    const imeiRequest = [
      {
        os: tradeDetails?.Make?.toUpperCase() === DeviceMakes?.APPLE ? 'ios' : tradeDetails?.Make,
        imei: tradeDetails?.IMEI,
      },
    ]
    actions.verifyImei(imeiRequest)
  }

  const handleUserInfoModal = () => {
    setShowCustomerInfoModal(false)
    setShowUpgradeConfirmationModal(true)
  }

  const handleConfirmationModal = () => {
    setShowUpgradeConfirmationModal(false)
    setShowQRCode(true)
    window.scrollTo(0, 0)
  }

  return (
    <div className="items-center flex flex-col ">
      {!showQRCode && (
        <>
          <ConfirmUpgrade />
          {tradeDetails && <UpgradeDetailsAndChecklist setShowFmipModal={setShowFmipModal} setShowCustomerInfoModal={setShowCustomerInfoModal} setShowQRCode={setShowQRCode} />}
        </>
      )}
      {showQRCode && <QrCodeContainer setShowQRCode={setShowQRCode} />}
      <FmipModal open={showFmipModal} setOpen={setShowFmipModal} handleFmipModal={handleFmipModal} />
      <CustomerInfoModal open={showCustomerInfoModal} setOpen={setShowCustomerInfoModal} handleUserInfoModal={handleUserInfoModal} />
      <UpgradeConfirmationModal open={showUpgradeConfirmationModal} setOpen={setShowUpgradeConfirmationModal} handleConfirmationModal={handleConfirmationModal} />
    </div>
  )
}

export default UpgradeDetails
