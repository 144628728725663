export default function errorMessageGenerator(validatorId, label, opts, resources) {
  const _label = (resources && resources[label]) || label || opts.label;

  switch (validatorId) {
    case 'required':
      return resources.VALIDATION_ERROR_REQUIRED_DEFAULT.replace('{label}', _label)

    case 'email':
      return resources.VALIDATION_ERROR_EMAIL_DEFAULT.replace('{label}', _label)

    case 'in':
      return resources.VALIDATION_ERROR_BETWEEN_DEFAULT.replace('{label}', _label).replace('{opts}', opts.join(', '))

    case 'numeric':
      return resources.VALIDATION_ERROR_NUMERIC_DEFAULT.replace('{label}', _label)

    case 'minLength':
      opts = opts || {}
      if (opts.minLength && opts.maxLength) {
        if (opts.minLength === opts.maxLength) {
          return resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
        }
        return resources.VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength).replace('{max}', opts.maxLength)
      }
      if (opts.minLength) {
        return resources.VALIDATION_ERROR_INT_MIN_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
      }

    case 'maxLength':
      opts = opts || {}
      if (opts.minLength && opts.maxLength) {
        if (opts.minLength === opts.maxLength) {
          return resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength)
        }
        return resources.VALIDATION_ERROR_LENGTH_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.minLength).replace('{max}', opts.maxLength)
      }
      if (opts.maxLength) {
        return resources.VALIDATION_ERROR_INT_MAX_DEFAULT.replace('{label}', _label).replace('{max}', opts.maxLength)
      }

    case 'min':
      return resources.VALIDATION_MIN_LENGTH_ERROR.replace('{label}', _label)

    case 'max':
      return resources.VALIDATION_MAX_LENGTH_ERROR.replace('{label}', _label)

    case 'maxAddress':
      return resources.VALIDATION_MAX_ADD_LENGTH_ERROR.replace('{label}', _label)
    
    case 'minAddress':
      return resources.VALIDATION_MIN_ADD_LENGTH_ERROR.replace('{label}', _label)
      
    case 'int':

      opts = opts || {}

      if (opts.min && opts.max) {
        return resources.VALIDATION_ERROR_INT_MIN_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.min).replace('{max}', opts.max)
      }

      if (opts.min) {
        return resources.VALIDATION_ERROR_INT_MIN_DEFAULT.replace('{label}', _label).replace('{min}', opts.min)
      }

      if (opts.max) {
        return resources.VALIDATION_ERROR_INT_MAX_DEFAULT.replace('{label}', _label).replace('{min}', opts.max)
      }

      // otherwise message should just require integer value
      return resources.VALIDATION_ERROR_INT_REQ_DEFAULT.replace('{label}', _label)

    case 'date':
      return resources.VALIDATION_ERROR_DATE_DEFAULT.replace('{label}', _label)

    case 'before':
    case 'after':

      if (opts) {
        return resources.VALIDATION_ERROR_AFTER_OPTS_DEFAULT.replace('{label}', _label).replace('{validatorId}', validatorId).replace('{opts}', opts.toString())
      }

      return resources.VALIDATION_ERROR_AFTER_DEFAULT.replace('{label}', _label).replace('{validatorId}', validatorId)

    case 'URL':
      return resources.VALIDATION_ERROR_URL_DEFAULT.replace('{label}', _label)

    case 'alpha':
      return resources.VALIDATION_ERROR_ALPHA_DEFAULT.replace('{label}', _label)

    case 'alphanumeric':
      return resources.VALIDATION_ERROR_ALPHANUM_DEFAULT.replace('{label}', _label)

    case 'invalidnum':
      return resources.VALIDATION_ERROR_INVALID_NUMBER_FORMAT.replace('{label}', _label)

    case 'mobileFormat':
      return resources.VALIDATION_ERROR_INVALID_NUMBER_FORMAT_ERROR.replace('{label}', _label)

    case 'mobileLength1':
      return resources.VALIDATION_ERROR_INVALID_NUMBER_LENGTH.replace('{label}', _label).replace('{length}', 10)
    
      case 'mobileLength2':
      return resources.VALIDATION_ERROR_INVALID_NUMBER_LENGTH.replace('{label}', _label).replace('{length}', 11)

    case 'password':
      return resources.VALIDATION_ERROR_PASSWORD_DEFAULT.replace('{label}', _label)
    
    case 'maxPostalCode':
      return resources.VALIDATION_ERROR_LENGTH_DEFAULT.replace('{label}', _label)
    
    case 'minPostalCode':
      return resources.VALIDATION_POSTALCODE_MIN_LENGTH_ERROR.replace('{label}', _label)
    
    case 'numericPostCode':
      return resources.VALIDATION_POSTALCODE_NUMERIC_ERROR.replace('{label}', _label)

    default:
      return resources.VALIDATION_ERROR_DEFAULT_DEFAULT.replace('{label}', _label)
  }
}
