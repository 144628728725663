import React from 'react'

const icon = ({ className }) => (
<svg width="25" height="24"className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="0.5" width="23" height="23" rx="3.5" stroke="#00AEBE"/>
</svg>
)

export default icon;


