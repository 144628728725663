import React, { useContext } from 'react'
import HNLogo from '../assets/freedom_logo.png'
import TradeLogo from '../assets/img-trade-save.png'
import Model from '../assets/model.png'
import Upgrade from '../assets/freedom_logo1.png'
import Logistics from '../assets/Trade-In.png'
import { useHistory } from 'react-router-dom'
import { store } from '../Store'
import { useActions } from '../Action'


function TradeOptions() {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const logisticsClick = () => {
        
        actions.setTradeInTab(true)
        actions.setIsTrade(true)
        actions.setLogisticsTab(false)
        actions.setReportingTab(false)
        actions.setContactUsTab(false)
        actions.setUpgradeTab(false)
        actions.setOptionsPage(false)
        history.replace('/tradein')
      }
    
      const upgradeClick = () => {
        actions.setUpgradeTab(true)
        actions.setIsTrade(false)
        actions.setLogisticsTab(false)
        actions.setReportingTab(false)
        actions.setContactUsTab(false)
        actions.setOptionsPage(false)
        history.replace('/upgrade')
      }
  return (
        <div className="min-h-screen flex flex-col items-center h-full">
            <div className='flex flex-row items-center content-center justify-center pt-20'>
                <img className="h-12" src={TradeLogo} alt="trade-img" />
                <img className="h-8" src={HNLogo} alt="asurion-img" />
            </div>
            <p className='mt-24'>What would you like to do?</p>
            <div className='flex flex-row justify-between  max-w-2xl w-full mt-8'>
                <img className="" src={Model} alt="model-img" style={{height: "280px"}}/> 
                <div className='flex flex-col justify-evenly'>
                    <img className="transform transition duration-500 hover:scale-110 cursor-pointer" src={Upgrade} alt="upgrade-img" style={{height: "105px"}} onClick={()=>{upgradeClick()}}/> 
                    <img className="transform transition duration-500 hover:scale-110 mt-4 cursor-pointer" src={Logistics} alt="logistics-img" style={{height: "105px"}} onClick={()=>{logisticsClick()}}/> 
                </div>        
            </div>
       </div>
  )
}

export default TradeOptions