import React from 'react'

const icon = ({ className }) => (
<svg width="25" height="24" className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="24" height="24" rx="4" fill="#00AEBE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0134 8.09164L10.6331 17.0035L7.3501 13.55L8.31647 12.6313L10.5644 14.996L16.9865 7.24121L18.0134 8.09164Z" fill="white"/>
</svg>
)

export default icon;
