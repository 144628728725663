import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/modal';
import Button from '../common/button';
import UpgradeConfirmationImage from "../../assets/confirmModal.png"

const UpgradeConfirmationModal = ({ open, setOpen, handleConfirmationModal }) => {
  return (
    <Modal open={open} setOpen={setOpen} showClose={false} containerClasses={'p-0 border-HnSecondaryAmethyst rounded-2xl max-w-[300px]'}>
      <div className="rounded-t-20" >
        <div  style={{ borderTopRightRadius: '14px', borderTopLeftRadius: '14px' ,borderColor: '#6B4EA0' }} className="bg-HnSecondaryAmethyst flex flex-col p-15 border-HnSecondaryAmethyst border-[.1px] text-white">
          <img src={UpgradeConfirmationImage} className="self-center mx-auto object-contain" style={{height: "120px"}} />
          <p 
            className={'mb-4'}
          >Are you sure you want to proceed? By generating this QR Code you cannot go back.</p>
        </div>
        <div className="py-14 items-center justify-center flex">
             <Button
                otherClass={'active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5'}
                label={'GO BACK'}
                padding="px-14 py-11"
                onClick={() => {
                    setOpen(false)
                }}
              />
             <Button
                otherClass={'active:bg-HnAccentSherpaBlue hover:bg-darkBlue text-sm ml-5'}
                label={'I AM SURE'}
                padding="px-14 py-11"
                onClick={() => {
                  handleConfirmationModal()
                }}
              />
        </div>
      </div>
    </Modal>
  );
};

UpgradeConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleConfirmationModal: PropTypes.func
};
export default UpgradeConfirmationModal;
