import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { store } from '../Store'
import { useActions } from '../Action'
import LabeledInputField from '../utils/custom-fields'
import HNLogo from '../assets/trade-logo.png'
import FreedomLogo from "../assets/freedom_logo.png"
import footerLogo from '../assets/img-asurion.png'
import ReCAPTCHA from 'react-google-recaptcha'

const INVOKE_ONCE = true

const Login = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, trigger, formState } = useForm({ mode: 'onChange' })
  let isValid = false
  const errorLength = Object.keys(errors).length
  isValid = errorLength > 0 ? false : true

  const displayNationalIdError = false
  const resources = state && state.resources
  const userAuthenticated = state && state.isUserAuthenticated
  const { isForgotPswdClicked } = state
  const login = state && state.Login
  const { email, password, Status, setCaptcha } = login
  const captcha = useRef(null)
  if (Status && Status.toLowerCase() === 'change_pass') {
    history.replace('/changepass')
  }
  if (Status && Status.toLowerCase() === 'success') {
    history.replace('/select')
    actions.setOptionsPage(true)
  }

  if (!setCaptcha && Status && (Status.toLowerCase() === 'account_lock' || Status.toLowerCase() === 'failure')) {
    captcha.current.reset();
  }
  if (isForgotPswdClicked) {
    history.replace('/forgotpass')
  }
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }
    confirmOrder()
  }

  const confirmOrder = () => {
    // var CryptoJS = require("crypto-js");
    // var encryptedPassword = CryptoJS.AES.encrypt(password, process.env.SECURITY_KEY).toString();

    // var bytes  = CryptoJS.AES.decrypt(encryptedPassword, process.env.SECURITY_KEY.toLowerCase());
    // var originalPassword = bytes.toString(CryptoJS.enc.Utf8);
    // console.log('password---',encryptedPassword, originalPassword)

    actions.login({
      username: email,
      password: password,
      captchatoken: setCaptcha,
    })
  }

  return (
    <>
      <div className="bg-img1">
        <form onSubmit={handleSubmit} className="container p-15">
          <div className="flex flex-row">
            <img className="h-40" src={HNLogo} alt="trade-img" />
            <img className="h-40 ml-30" src={FreedomLogo} alt="asurion-img" />
          </div>
          <div className="text-textBlack font-AvenirNextRegular font-semibold mt-90 text-3xl">Welcome to the Trade-In and Mobile Freedom Store Portal</div>
          <div className="text-lg font-AvenirNextRegular text-dark-gray mt-20">If you have your Trade-In Store Agent account, please login to start the journey</div>
          <div style={{ maxWidth: 'fit-content' }} className="mt-70 text-lightBlue p-15 bg-HnBlueOpacity10">
            <p>You can access the Mobile Freedom store portal by using your Trade In log in details</p>
          </div>
          <div className="flex-row inline-flex">
            <div className="flex-col inline-block mt-12 mr-5">
              <label className="text-sm leading-19 font-AvenirNextRegular text-dark-gray">{resources.HARWAY_NORMAN_AGENT_ID_LABEL}</label>
              <br />
              <LabeledInputField
                type="text"
                name="vodafoneAgentId"
                label="HARWAY_NORMAN_AGENT_ID_LABEL"
                placeholder="HARWAY_NORMAN_ID_PLACEHOLDER"
                className=" mt-3 mr-5 font-AvenirNextRegular"
                onChange={actions.setVodafoneAgntID}
                register={register}
                errors={errors}
                schema="verification"
                hideLabel
              />
              <img className="img-footer w-24" src={footerLogo} alt="asurion" />
            </div>
            <div className="flex-col inline-block mt-12 mr-5">
              <label className="text-sm leading-19 font-AvenirNextRegular text-dark-gray">{resources.HARWAY_NORMAN_LABEL_PASSWORD}</label>
              <br />
              <LabeledInputField
                type="password"
                name="password"
                labelClass=""
                label="HARWAY_NORMAN_LABEL_PASSWORD"
                placeholder="HARWAY_NORMAN_PSWD_PLACEHOLDER"
                className={`mt-3 ${displayNationalIdError ? 'input_box_error' : ''}`}
                onChange={actions.setPassword}
                register={register}
                errors={errors}
                schema="verification"
                required={true}
                passwordIconClass={{left: '88%', top:'18px'}}
                hideLabel
              />{' '}
              <div className="mt-3">
                <a
                  className="forgot leading-19 font-AvenirNextRegular text-textPurple font-bold underline text-sm"
                  onClick={() => {
                    actions.navigateToForgotPassword()
                  }}
                >
                  {resources.HARVEY_NORMAN_LBL_FORGOT_PASSWORD}
                </a>
              </div>
              {state && state.Login && state.Login.Status === 'failure' && (
                <div className="">
                  <div className="mt-20">
                    <p className="error-text font-AvenirNextRegular">Incorrect username or password.</p>
                  </div>
                </div>
              )}
              {state && state.Login && state.Login.Status === 'account_lock' && (
                <div className="">
                  <div className="mt-20">
                    <p className="error-text font-AvenirNextRegular">Too many failed attempts. Your account has been locked, please try again in 10 minutes.</p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex-col inline-block mt-12 pt-3">
            <button style={{marginTop: '16px', padding: "14px 10px", cursor: !(isValid && email && password && setCaptcha) && 'default' }} disabled={!(isValid && email && password && setCaptcha)}  type="submit" className={`focus:outline-none text-white text-base rounded-6 ${isValid && email && password && setCaptcha ? ' btn-search bg-HnSecondaryAmethyst' : 'btn-search bg-textPurple'} `}>
                {resources.HARWAY_NORMAN_LOGIN_BUTTON}
              </button>
            </div>
          </div>
          <div className="flex-row inline-flex">
            <ReCAPTCHA ref={captcha} sitekey={process.env.RECAPTCHA_SITE_KEY} onChange={actions.setCaptcha} onExpired={actions.setCaptcha} />
            <hr />
          </div>
        </form>
      </div>
    </>
  )
}
export default Login
