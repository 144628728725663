import React, { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import spanIconDown from '../../assets/ic-chevron_up.png'
import spanIconUp from '../../assets/ic-chevron_down.png'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import LabeledInputField, { DateInputField } from '../../utils/custom-fields'
import Paginate from '../pagination'
const moment = require('moment')
import vodafoneImg from '../../assets/img-harwey-norman.png'
import UpArrowPng from  "../../assets/ic-arrow-up-blue.png"
import DownArrowPng from  "../../assets/ic-down-arrow.png"

const LogisticsBatchDetails = () => {
  const { state, dispatch } = useContext(store)

  let { LogisticsBatchDetailsInputField, resources, logisticsBatchData, batchSelectedPage, Login, showCanvasReprint, AusPost } = state
  let ConsignmentNo = AusPost && AusPost.ConsignmentNo

  const { BatchItems, TotalRecords } = logisticsBatchData

  const pageLimit = 10
  const totalPages = Math.ceil(TotalRecords / pageLimit)
  const range = (from, to, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
      range.push(i)
      i += step
    }
    return range
  }
  let pages = range(1, totalPages)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const setSelectedPage = (index) => {
    actions.setBatchSelectedPage(index)
  }

  useEffect(() => {
    searchBatchDetails()
  }, [true])

  useEffect(() => {
    return () => {
      actions.setBatchNumber(undefined)
      actions.setBatchConsignmentNumber(undefined)
      actions.setBatchStartDate(undefined)
      actions.setTradeIDNumber(undefined)
      actions.setBatchSelectedPage(0)
      actions.setLogisticsBatchData({})
    }
  }, [])

  const { register, errors, setValue } = useForm({ mode: 'onChange' })
  const actions = useActions(state, dispatch)
  let [data, setData] = useState(BatchItems)
  let [records, setTotalRecords] = useState(TotalRecords)
  const [currentData, setCurrentData] = useState([])
  let selectedBatch = currentData.reduce((agr, i) => i.groupedItems[0].isPrint === true ? [...agr,i.groupedItems[0]] : agr,[])

  let filteredData = []

  useEffect(() => {
    if (logisticsBatchData) {
      setData(BatchItems)
      setTotalRecords(TotalRecords)
    }

    getFilteredData()
  }, [logisticsBatchData])

  useEffect(() => {
    searchBatchDetails()
  }, [batchSelectedPage])

  useEffect(() => {
    getFilteredData()
  }, [JSON.stringify(data)])

  const getFilteredData = () => {
    filteredData = []
    if (data && data.length > 0) {
      let sortedData = []
      if (data.length > 1) {
        sortedData = [...data].sort(sortData)
      } else {
        sortedData = [...data]
      }
      sortedData.forEach(function (item) {
        if (item.DateTime.includes('T')) {
          let formattedDateTime = ''
          let sDate = item.DateTime.split('T')
          formattedDateTime = moment(sDate[0], 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + sDate[1].split('.')[0]
          item.DateTime = formattedDateTime
        }

        if (!this[item.Batch]) {
          let createdDate = item.BatchCreatedDate.split('T')
          let cdate = moment(createdDate, 'YYYY-MM-DD').format('DD MMM YYYY')
          this[item.Batch] = { BatchCreatedDate: cdate, Batch: item.Batch, ConsignmentNo: item.ConsignmentNo, isSelected: item.isSelected, groupedItems: [] }
          filteredData.push(this[item.Batch])
        }
        this[item.Batch].groupedItems.push(item)
      }, [])
    }
    setCurrentData(filteredData)
  }

  const sortData = (obj1, obj2) => {
    if (obj1.Batch > obj2.Batch) {
      return -1
    } else {
      return 1
    }
  }
  const toggleSelection = (id, reprintFlag) => {
    BatchItems &&
      BatchItems.map((el) => {
        if (el.Batch === id) {
          if (reprintFlag) {
            el.isPrint = true;
          } else {
            el.isSelected = !el.isSelected;
          }
        } else {
          el.isSelected = false;
        }

        return el
      })
    actions.setLogisticsBatchData(BatchItems)
  }

  const searchBatchDetails = (isSearch = false) => {
    if (isSearch) {
      actions.setBatchSelectedPage(0)
    }

    let GetBatchDetailsRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      StartDate: LogisticsBatchDetailsInputField.startDate,
      BatchId: LogisticsBatchDetailsInputField.batchNumber,
      ConsignmentId: LogisticsBatchDetailsInputField.consignmentNumber,
      TradeQuoteNbr: LogisticsBatchDetailsInputField.tradeId,
      PageSize: 10,
      PageNumber: isSearch ? 1 : batchSelectedPage + 1,
    }

    actions.getLogisticsBatchData({ GetBatchDetailsRequest })
  }

  const resetFields = () => {
    actions.setBatchNumber(undefined)
    actions.setBatchConsignmentNumber(undefined)
    actions.setBatchStartDate(undefined)
    actions.setTradeIDNumber(undefined)
    if (batchSelectedPage == 0) {
      let GetBatchDetailsRequest = {
        PartnerStoreId: Login && Login.PartnerStoreId,
        PageSize: 10,
        PageNumber: 1,
      }
      actions.getLogisticsBatchData({ GetBatchDetailsRequest })
    }
    actions.setBatchSelectedPage(0)
    setValue('batchNo', '')
    setValue('consignmentNo', '')
    setValue('batchStartDate', '')
    setValue('tradeId', '')
  }
  let capitalizeFirst = (string) => {
    return string && string.charAt(0) && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  const reprint = async (batch) => {
    toggleSelection(batch, true)
    let CreateParcelRequest = {
      BatchId: batch.toString(),
    }
    let GetBatchDetailsRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      PageSize: 10,
      PageNumber: batchSelectedPage + 1,
    }
    actions.createParcelApi({ CreateParcelRequest }, GetBatchDetailsRequest)
  }
  let batchPageCount = Math.ceil(TotalRecords / pageLimit)

  return (
    <div>
      <div>
        <div className="flex w-full items-center">
          <h2 className="text-17 font-AvenirNextRegular text-dark-gray mt-31 w-400 font-semibold mb-4">Search</h2>
        </div>
        <div className="flex flex-row items-center">
                    <div className="flex flex-col w-fit">
                        <span className="text-xs font-AvenirNextRegular text-dark-gray">{resources["LOGISTICS_LBL_BATCH_NO"]}</span>
                      
                        <LabeledInputField
                          className="order-no-input search-font"
                          type="text"
                          name="batchNo"
                          schema="logistics"
                          // placeholder="LOGISTICS_LBL_BATCH_NO"
                          onChange={actions.setBatchNumber}
                          register={register}
                          errors={errors}
                          hideLabel
                        />

                    </div>
                    <div className="flex flex-col w-fit">
                        <span className="text-xs font-AvenirNextRegular text-dark-gray">{resources["LOGISTICS_LBL_TRADE_ID"]}</span>
                      
                        <LabeledInputField
                          className="order-no-input search-font"
                          type="text"
                          name="tradeId"
                          schema="logistics"
                          // placeholder="LOGISTICS_LBL_BATCH_TRADE_ID"
                          onChange={actions.setTradeIDNumber}
                          register={register}
                          errors={errors}
                          hideLabel
                        />

                    </div>
                    <div className="flex flex-col w-fit">
                        <span className="text-xs font-AvenirNextRegular text-dark-gray">{resources["LOGISTICS_LBL_BATCH_DATE"]}</span>
                      
                        <DateInputField
                          onChange={actions.setBatchStartDate}
                          // placeholder="LOGISTICS_LBL_BATCH_DATE"
                          value={LogisticsBatchDetailsInputField && LogisticsBatchDetailsInputField.startDate ? LogisticsBatchDetailsInputField.startDate : ''}
                          format="dd-MM-yyyy"
                        />

                    </div>
                    <div className="flex flex-col w-fit">
                        <span className="text-xs font-AvenirNextRegular text-dark-gray">{resources["LOGISTICS_LBL_BATCH_COSIGNMENT_NO"]}</span>
                      
                        <LabeledInputField
                          className="consignment-no-input search-font"
                          type="text"
                          name="consignmentNo"
                          schema="logistics"
                          // placeholder="LOGISTICS_LBL_BATCH_COSIGNMENT_NO"
                          onChange={actions.setBatchConsignmentNumber}
                          register={register}
                          errors={errors}
                          hideLabel
                        />

                    </div>
          
         
          {/* <LabeledInputField
          className="batch-date-input search-font"
          type="date"
          register={register}
          errors={errors}
          placeholder="LOGISTICS_LBL_BATCH_DATE"
          onChange={actions.setBatchStartDate}
          name="batchStartDate"
          schema="logistics"
          hideLabel
          value={LogisticsBatchDetailsInputField ? LogisticsBatchDetailsInputField.startDate : ''}
        /> */}
         

         <div className='self-end'>
            <button className={`font-bold text-sm  bg-lightBlue text-white rounded-6 ml-15 p-10`} onClick={() => searchBatchDetails(true)}>
                      {resources.HARVEY_NORMAN_SEARCH_BUTTON}
            </button>
            <button className="font-bold text-sm bg-white border border-lightBlue  text-lightBlue rounded-6 py-9 px-11 ml-10" onClick={() => resetFields()}>
                      {resources.HARVEY_NORMAN_RESET_BUTTON}
            </button>
          </div>
        </div>
        {currentData && currentData.length > 0 ? (
          <table className="mt-5 text-sm font-AvenirNextRegular font-bold w-full">
            <thead className="text-transform: uppercase  bg-textPurple  text-white text-left font-AvenirNextRegular">
              <tr className="w-full h-60 ">
                <th className=" h-34 pl-67 w-197">date</th>
                <th className=" h-34 pl-5 w-100">Batch No.</th>
                <th className=" h-34 pl-5">Star Track Consignment No.</th>
                <th></th>
              </tr>
            </thead>
            {currentData &&
              currentData.map((table_Detail, index) => (
                <tbody style={{ borderBottomColor: 'rgba(142, 104, 200, 0.50)', borderBottomWidth: '1px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}} key={index} className="">
                  <tr style={{backgroundColor: table_Detail.isSelected  ? 'rgba(0, 174, 190, 0.10)': "#FFFFFF"}} className={`text-left h-50 text-sm font-AvenirNextRegular font-bold`}>
                    <td style={{borderBottomLeftRadius: '0px'}} className="h-50 w-197">
                      <img
                        className="inline-block  pl-26 font-AvenirNextRegular font-bold cursor-pointer"
                        src={table_Detail.isSelected ? UpArrowPng : DownArrowPng}
                        onClick={() => toggleSelection(table_Detail.Batch)}
                      />
                      <span className="pl-21 w-130 text-left text-sm font-AvenirNextRegular">{table_Detail.BatchCreatedDate}</span>
                    </td>
                    <td className="w-100 h-10 pl-5  text-sm font-AvenirNextRegular">{table_Detail.Batch}</td>
                    <td className=" h-10 pl-5  text-sm font-AvenirNextRegular">{table_Detail.ConsignmentNo}</td>
                    <td style={{borderBottomRightRadius: '0px'}} className=" h-34 pl-5 pr-5 float-center py-12">
                      <button
                      style={{
                        borderRadius: '30px',
                        padding: '10px 15px',
                        borderRadius: '30px',
                        border: !table_Detail.isSelected ? '1px solid #8E68C8' : '1px solid #00AEBE',
                        color: !table_Detail.isSelected ? '#8E68C8' : '#00AEBE',
                        fontWeight: 700
                      }}
                      className={`text-transform: uppercase font-AvenirNextRegular`} onClick={(batch) => reprint(table_Detail.Batch)}>
                        {table_Detail.ConsignmentNo ? 'Reprint' : resources.VODAFONE_PRINT_CONFIRM_BUTTON}
                      </button>
                    </td>
                  </tr>
                  {table_Detail.isSelected && table_Detail.groupedItems && (
                    <tr style={{borderBottomColor: 'rgba(142, 104, 200, 0.50)', borderBottomWidth: '1px'}} className="text-left  text-sm">
                      <td colSpan="4" className="p-0">
                        {/*  <table className="thead text-sm border-white w-full  "> */}
                        <table className={`text-xs w-full`}>
                          <thead>
                            <tr  style={{borderBottomColor: 'rgba(0, 174, 190, 0.50)', borderBottomWidth: '1px', backgroundColor: 'rgba(0, 174, 190, 0.10)', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}} className="text-transform: uppercase text-darkBlue text-left  font-AvenirNextRegular font-bold">
                              <th style={{borderTopLeftRadius: '0px'}} className="w-20 pl-10 h-10">Trade ID</th>
                              <th className="w-10 h-10 batchdetailpadding"> Date / Time </th>
                              <th className="w-28 h-10 batchdetailpadding"> Device IMEI/ Serial No. </th>
                              <th className="h-10 w-28 batchdetailpadding">Make</th>
                              <th className="w-80 h-10 batchdetailpadding">Model/Variant</th>
                              <th className="h-10 w-70 batchdetailpadding">Product Type</th>
                              <th className="h-10 w-10 batchdetailpadding">Condition</th>
                              <th className="w-100 h-10 batchdetailpadding">Store Code</th>
                              <th className="w-200 h-34 batchdetailpadding">Store Name or Location</th>
                              <th className="h-10 w-20 batchdetailpadding">Member ID</th>
                              <th className="h-10 w-130 batchdetailpadding">Team Member</th>
                              <th className="h-10 w-20 batchdetailpadding">Status</th>
                              <th className="h-10 w-60 batchdetailpadding">Batch</th>
                              <th style={{borderTopRightRadius: '0px'}} className="h-10 w-120 batchdetailpadding">Consignment No.</th>
                            </tr>
                          </thead>
                          {table_Detail.groupedItems.map((item, index) => (
                            <tbody style={{backgroundColor: 'transparent'}} key={index}>
                              <tr style={{borderBottomColor: 'rgba(0, 174, 190, 0.20)', borderBottomWidth: '1px', backgroundColor: 'rgba(0, 174, 190, 0.05)' , borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }} className="text-left h-10 text-sm font-AvenirNextRegular font-normal">
                                <td style={{borderBottomLeftRadius: '0px'}} className="w-20 h-10 pl-10">{item.ReferenceNo}</td>
                                <td className="w-10 h-10 batchdetailpadding">{item.DateTime}</td>
                                <td className="w-28 h-50 batchdetailpadding text-left">{item.DeviceIMEI}</td>
                                <td className="h-10 w-28 batchdetailpadding">{item.Make}</td>
                                <td className="w-80 h-10 batchdetailpadding">{item.Model}</td>
                                <td className="h-10 w-70 batchdetailpadding">{item.ProductType}</td>
                                <td className="h-50 w-10 batchdetailpadding">{item.Condition}</td>
                                <td className="w-20 h-10 batchdetailpadding ">{item.StoreCode}</td>
                                <td className="w-200 h-10 batchdetailpadding">{item.StoreName}</td>
                                <td className="h-10 w-20 batchdetailpadding">{item.MemberId}</td>
                                <td className="h-10 w-130 batchdetailpadding">{item.TeamMember}</td>
                                <td className="h-10 w-20 batchdetailpadding"> {item.Status || '-'}</td>
                                <td className="h-10 w-60 batchdetailpadding text-red-1">{item.Batch}</td>
                                <td style={{borderBottomRightRadius: '0px'}} className="h-10 w-120 batchdetailpadding">{item.ConsignmentNo}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
          </table>
        ) : (
          <div className="mt-5">No Records Found</div>
        )}
        {currentData && currentData.length > 0 && (
          <div className="flex justify-between mt-17">
            <div>
              Page {batchSelectedPage + 1} of {batchPageCount}
            </div>
            <Paginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={<a>...</a>}
              breakClassName={'break-me'}
              pageCount={batchPageCount}
              selectedPage={batchSelectedPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={(page) => setSelectedPage(page.selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        )}
      </div>
      {showCanvasReprint && (
				<div>
					<div id="reprintme" className="w-1064 h-900 mt-48 font-AvenirNextRegular">
						<img className="py-14 inline-block mr-70" src={vodafoneImg} alt="hn-img" />
						<div className="float-right inline-block">
							<p className="mt-20 log-print-date-font leading-16">
								{moment().format('D MMMM YYYY h:mm:ss')}
							</p>
						</div>
						<table className="mt-30 text-sm log-print-border">
							<thead>
								<tr className="text-transform: uppercase border-white log-print-header-color text-white h-70 text-left font-AvenirNextRegular">
									<th className="w-20 pl-1">
										{'>= 1'} <br />
										days
									</th>
									<th className="w-50 h-34 pl-1">
										Reference <br />
										No.
									</th>
									<th className="w-50 h-34 pl-2">
										Date/
										<br />
										Time
									</th>
									<th className="h-34 w-50 pl-2">Device IMEI/ Serial No.</th>
									<th className="h-34 w-70 pl-2">
										Device Name <br />
									</th>
									<th className="h-34 w-50 pl-2">
										Product
										<br />
										Type
									</th>
									<th className="w-10 h-34 pl-1">Condition</th>
									<th className="w-30 h-34 pl-1">
										Store <br />
										Code
									</th>
									<th className="h-34 w-70 pl-2">
										Store Name/
										<br />
										Location
									</th>
									<th className="h-34 w-50 pl-2">Member ID</th>
									<th className="h-34 w-50 pl-2">
										Consignment <br />
										No.
									</th>
								</tr>
							</thead>
							{
								selectedBatch &&
								selectedBatch.map((table_Detail, index) => (
									//table_Detail.groupedItems.map((item, index) => (
									/* batchingItems &&
												batchingItems.map((table_Detail, index) => ( */
									<tbody key={index}>
										<tr className="text-left text-dark-gray h-50 text-sm log-print-border bg-white">
											<td className="w-20 pl-1">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
											<td className="w-20 h-50 pl-1">{table_Detail.ReferenceNo}</td>
											<td className="w-50 h-70 pl-2 text-left">
												{table_Detail.DateTime ? (
													<div>
														{table_Detail.DateTime}
													</div>
												) : (
														'-'
													)}
											</td>
											<td>
												<td className="w-50 h-70 pl-2">
													<span className=" font-bold">{'P'}&nbsp;</span>
													{table_Detail.DeviceIMEI}
													<br />
													{table_Detail.SecondDeviceIMEI ? (
														<div>
															<span className=" font-bold">{'S'}&nbsp;</span>
															{table_Detail.SecondDeviceIMEI}{' '}
														</div>
													) : (
															''
														)}
												</td>
											</td>
											<td className="h-34 w-50 pl-2">
												{table_Detail.Make} <br />
												{table_Detail.Model}
											</td>
											<td className="h-20 w-20 pl-2">{table_Detail.ProductType}</td>
											<td className="w-10 h-20 pl-1 text-center">{table_Detail.Condition}</td>
											<td className="w-30 h-20 pl-1">{table_Detail.StoreCode}</td>
											<td className="h-50 w-70 pl-2">{table_Detail.StoreName}</td>
											<td className="h-50 w-50 pl-2">{table_Detail.MemberId}</td>
											<td className="h-50 w-50 pl-2">{ConsignmentNo || table_Detail.ConsignmentNo}</td>
										</tr>
									</tbody>
								))
								//))
							}
						</table>
					</div>
				</div>
			)}
    </div>
  )
}
export default LogisticsBatchDetails
